import { listParams, store } from './Map'
import { updateStructureList, initDropdown } from './MapList'

// Pagination navigation icons
const prevsiousIcon = `<svg class="pagination-arrow icon" style="transform:rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg>`
const nextIcon = `<svg class="pagination-arrow icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg>`

const anchor = document.querySelector('#MapAnchor')
const resultsList = document.querySelector('[data-map-resultsList]')

export function paginationMarkup() {

  const totalPages = listParams.pageNb // total number of page in the pagination
  const currentPage = listParams.currentPage // begin at 0

  // create pagination container
  const paginationContainer = document.createElement('nav')
  paginationContainer.classList.add('MapResults-pagination')
  paginationContainer.setAttribute('data-pagination', '')
  paginationContainer.setAttribute('role', 'navigation')
  paginationContainer.setAttribute('aria-label', 'Pagination')

  // Create pagination list
  const paginationList = document.createElement('ul')
  paginationList.classList.add('pagination')

  // get the current page Value 
  const isCurrentPage = (i) => {
    return currentPage === i ? true : false
  }
  
  // Add every pagination item
  for (let i = 0; i < totalPages; i++) {
    const isCurrent = isCurrentPage(i)
    const paginationItem = createPaginationItem(isCurrent, i)
    paginationList.appendChild(paginationItem)
  }
  
  // Add previous and/or next pagination button
  paginationList.prepend(createPaginationButton(true, false, totalPages, currentPage))
  paginationList.append(createPaginationButton(false, true, totalPages, currentPage))

  // Append list in in pagination container
  paginationContainer.appendChild(paginationList)

  return paginationContainer
}


function createPaginationItem (isCurrent, counter) {
  // Create pagination Item
  const paginationItem = document.createElement('li')
  paginationItem.classList.add('page-item')
  if (isCurrent) {
    paginationItem.classList.add('active')
    paginationItem.querySelector('.page-link')
    const accessElement = document.createElement('span')
    accessElement.classList.add('sr-only')
    accessElement.innerHTML = "Page active"
    paginationItem.appendChild(accessElement)
  }

  // Create pagination Button
  const paginationButton = document.createElement('a')
  paginationButton.classList.add('page-link')
  paginationButton.innerHTML = counter + 1
  paginationButton.setAttribute('aria-label', `Page ${counter + 1}`)
  if (isCurrent) paginationButton.setAttribute('disabled', 'disabled')

  // Append button in li tag and li tag in list tag
  paginationItem.appendChild(paginationButton)

  return paginationItem
}

function createPaginationButton(isPrevisous = false, isNext = false, totalPages, currentPage) {
  // Create li pagination item
  const paginationItem = document.createElement('li')
  paginationItem.classList.add('page-item')

  if(isPrevisous) {
    paginationItem.classList.add('prev')

    if(currentPage === 0) paginationItem.classList.add('disabled')
  }

  if(isNext) {
    paginationItem.classList.add('next')

    if(currentPage === totalPages - 1) paginationItem.classList.add('disabled')
  }

  // Create pagination previous Button
  const paginationButton = document.createElement('a')
  paginationButton.classList.add('page-link')
  
  // Add Icon to the Button then add button to the item  
  if(isPrevisous) {
    paginationButton.innerHTML = prevsiousIcon
    if(currentPage === 0) paginationButton.setAttribute('disabled', 'disabled')
  }

  if(isNext) {
    paginationButton.innerHTML = nextIcon     
    if(currentPage === totalPages - 1) paginationButton.setAttribute('disabled', 'disabled')
  }

  paginationItem.append(paginationButton)

  return paginationItem
}

export function updatePagination() {
  const pagination = document.querySelector('[data-pagination]')
  
  // Remove the old pagination
  if (pagination) pagination.remove()
  // don't add a pagination if there is less result than the limit per page 
  if (store.structures.length <= listParams.limitPerPage) return

  // create the nex pagination markup
  const markup = paginationMarkup()
  const prevButton = markup.querySelector('.page-item.prev .page-link')
  const nextButton = markup.querySelector('.page-item.next .page-link')
  const paginationItems = markup.querySelectorAll('.page-item:not(.next):not(.prev) .page-link:not([disabled=disabled])')
  const paginationTotalItems = markup.querySelectorAll('.page-item .page-link:not([disabled=disabled])')

  prevButton.setAttribute('aria-label', 'Page précédente')
  nextButton.setAttribute('aria-label', 'Page suivante')

  resultsList.parentNode.append(markup)
  
  paginationItems.forEach((item) => {
    item.addEventListener('click', function(e) {
      listParams.currentPage = parseInt(e.currentTarget.innerText) - 1
    })
  })
  
  prevButton.addEventListener('click', function () {
    listParams.currentPage = listParams.currentPage - 1
  })
  
  nextButton.addEventListener('click', function () {
    listParams.currentPage = listParams.currentPage + 1
  })

  paginationTotalItems.forEach((item) => {
    item.addEventListener('click', function(e) {
      anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      setTimeout(() => {
        updateStructureList()
        initDropdown()
      }, 650);
    })
  })

}