import getSiblings from '../../../js/helpers/getSiblings'


class MenuMobile {
  constructor(menuContainer) {
    this.menuContainer = menuContainer
    this.menuWrapper = null
    this.menu = menuContainer.querySelector('[data-menuMobile-menu]')
    this.buttons = menuContainer.querySelectorAll('[data-menuMobile-hasSub]')
    this.backButtons = menuContainer.querySelectorAll('[data-menuMobile-back]')
    this.menuSubs = menuContainer.querySelectorAll('[data-menuMobile-sub]')

    this.init()
  }

  initMenu() {
    const menuContainer = this.menuContainer
    const menu = this.menu
    const menuButtons = this.buttons
    const menuSubs = this.menuSubs

    this.menuWrapper = document.createElement('div')
    this.menuWrapper.classList.add('MenuMobile-wrapper')

    menuContainer.insertBefore(this.menuWrapper, menu)
    this.menuWrapper.appendChild(menu)

    this.menuWrapper.style.height = `${menu.offsetHeight}px`

    menuButtons.forEach((menuButton, index) => {
      menuButton.setAttribute('aria-expanded', 'false')
      menuButton.setAttribute('id', `mobileMenuItem-${index + 1}`)
      menuButton.setAttribute('aria-controls', `mmItem-${index + 1}`)
    })

    menuSubs.forEach((menuSub, index) => {
      menuSub.setAttribute('id', `mmItem-${index + 1}`)
      menuSub.setAttribute('aria-labelledby', `mobileMenuItem-${index + 1}`)
    })
  }

  openSubContent() {
    const menuButtons = this.buttons
    const menuContainer = this.menuContainer
    const menuWrapper = this.menuWrapper

    menuButtons.forEach(menuButton => {
      const parent = menuButton.parentNode
      const sub = parent.querySelector('[data-menuMobile-sub]')

      menuButton.addEventListener('click', () => {
        const siblings = getSiblings(menuButton.parentNode)
        menuContainer.scroll(0,10)

        menuButton.setAttribute('aria-expanded', 'true')

        siblings.forEach(sibling => {
          sibling.classList.add('is-hidden')
        })

        sub.classList.add('is-active')
        sub.querySelector('[data-menuMobile-back]').focus({preventScroll:true})
        const backgroundImageUrl = menuButton.getAttribute('data-menuDesktop-background')

        if (sub.classList.contains('MenuMobile-sub--subrubrics')) {
          menuContainer.classList.add('MenuMobile-container--subrubricsSelected')
          menuContainer.querySelector('.QuickAccess-list .QuickAccess-item:nth-child(2)').style.backgroundImage = `url(${backgroundImageUrl})`
        } else {
          menuContainer.classList.add('MenuMobile-container--articlesSelected')
        }
        // add the background in mobile
        if(backgroundImageUrl){
          menuContainer.style.backgroundImage = `url(${backgroundImageUrl})`
        }

        menuWrapper.style.height = `${sub.offsetHeight}px`
      })
    })
  }

  closeSubContent() {
    const menuBackButtons = this.backButtons
    const menuContainer = this.menuContainer
    const menuWrapper = this.menuWrapper

    menuBackButtons.forEach(menuBackButton => {
      const parent = menuBackButton.parentNode
      const parentButton = parent.closest('[data-menuMobile-item]')
      const parentBlock = parent.parentNode.closest('[data-menumobile-sub]') || parent.parentNode.closest('[data-menumobile-menu]')

      menuBackButton.addEventListener('click', () => {
        const parentButtonSiblings = getSiblings(parentButton)

        parentButtonSiblings.forEach(parentButtonSibling => {
          parentButtonSibling.classList.remove('is-hidden')
        })

        parentButton.querySelector('[data-menuMobile-hasSub]').setAttribute('aria-expanded', 'false')
        parentButton.querySelector('[data-menuMobile-hasSub]').focus({preventScroll:true})

        parent.classList.remove('is-active')

        if (parentBlock.classList.contains('MenuMobile-sub--subrubrics')) {
          menuContainer.classList.remove('MenuMobile-container--articlesSelected')
        } else {
          menuContainer.removeAttribute('style')
          menuContainer.classList.remove('MenuMobile-container--subrubricsSelected')
        }
        
        menuWrapper.style.height = `${parentBlock.offsetHeight}px`
      })
    })
  }

  closeMenu() {
    const menu = this.menu
    const menuContainer = this.menuContainer
    const menuWrapper = this.menuWrapper
    const menuSubs = this.menuSubs
    const menuButtons = this.buttons
    menuContainer.removeAttribute('style')


    menuButtons.forEach(menuButton => {
      menuButton.parentNode.classList.remove('is-hidden')
    })

    menuSubs.forEach(menuSub => {
      menuSub.classList.remove('is-active')

      menuContainer.classList.remove('MenuMobile-container--articlesSelected')
      menuContainer.classList.remove('MenuMobile-container--subrubricsSelected')

      menuWrapper.style.height = `${menu.offsetHeight}px`
    })
  }

  init() {
    this.initMenu()
    this.openSubContent()
    this.closeSubContent()
  }
}

function init() {
  const searchTrigger = document.querySelector('[data-search-trigger]')
  const menuButton = document.querySelector('[data-menuMobile-trigger]')
  const menuOverlay = document.querySelector('[data-menuMobile-overlay]')
  const menuContainer = document.querySelector('[data-menuMobile-container]')

  if (!menuContainer) return

  const menuInit = new MenuMobile(menuContainer)

  menuButton.addEventListener('click', () => {
    if (document.body.classList.contains('menu-open--mobile')) {
      menuInit.closeMenu()
    }
  })

  menuOverlay.addEventListener('click', () => {
    menuInit.closeMenu()
  })

  searchTrigger.addEventListener('click', () => {
    menuInit.closeMenu()
  })
}

export default { init }