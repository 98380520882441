<template>
  <div>
    <div class="Result" data-result data-tracking="TPE">

      <!-- Those two loaders will disepear from the DOM as soon as the ajax call is ended and successful -->
      <!-- Loader in yellow part -->
      <div class="container container--small container--isStatic">
        <div class="container-container">
          <div class="container-inner">
            <div class="container-content">
              <div class="Result-loader"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Loader in white part -->
      <div class="container container--small container--isStatic container--white">
        <div class="container-container">
          <div class="container-inner">
            <div class="container-content">
              <div class="Result-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import { axiosClient } from '../../../../js/axiosClient'
// import OpacityHover from '../../../../js/opacityHover'
import listArticle from '../../../ListArticle/ListArticle'

export default {
  name: "Result",
  props: {
    results: {
      required: true,
      type: Array,
    },
    index: {
      required: true,
      type: Number,
    },
    componentType: {
      required: true,
      type: String
    },
    componentId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      refCount: 0,
      isLoading: false,
    }
  },
  methods: {
    resetForm() {
      this.$emit("Result::reset-form")
    },
    setLoading(isLoading) { // Function used with the code in created to display the loader when axios is quering
      if (isLoading) {
        this.refCount++;
        this.isLoading = true;
      } else if (this.refCount > 0) {
        this.refCount--;
        this.isLoading = (this.refCount > 0);
      }
    },
  },
  async mounted() {
    let dataResult = document.querySelector('[data-result]')
    if(this.componentType === 'Result'){
      await axios.post(
        '/result_tpe/'+ this.componentId
      ).then(async (response) => {
        dataResult.innerHTML = await response.data

        // Here to add an external button the possibility to reset Vue form
        // After the content has been loaded
        let resetButton = document.querySelector('.Result-reset')
        resetButton.addEventListener('click', this.resetForm)
        return tC.event.tpeFormEnd(this, {'formName':'TPE'});
      }).catch((error) => {
        console.log(error);
      })
    }

    listArticle.init()
    
  },
  created() {
    // Interceptors that handle the loading props allowing to display or not he loading icon on load 
    axiosClient.interceptors.request.use((config) => {
      this.setLoading(true);
      return config;
    }, (error) => {
      this.setLoading(false);
      return Promise.reject(error);
    });
    axiosClient.interceptors.response.use((response) => {
      this.setLoading(false);
      return response;
    }, (error) => {
      this.setLoading(false);
      return Promise.reject(error);
    });
  }
}
</script>