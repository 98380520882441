<template>
  <div class="Form-input-icon">
    <svg v-if="!isLoading" class="icon icon--valid" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 14">
      <path d="m3.85 11.270089 4.440104 5.481585L18.751563 4.769531" transform="scale(.68182 .63636)" fill="none" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke="#000"/>
    </svg>

    <svg v-if="!isLoading" class="icon icon--error" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
      <path fill="currentColor" d="M34 4l-4-4-13 13L4 0 0 4l13 13L0 30l4 4 13-13 13 13 4-4-13-13"></path>
    </svg>
    
    <svg v-if="isLoading" class="InputAddress-loading" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
  </div>
</template>

<script>
  export default {
    name: "FormInputIcon",
    props: {
      isLoading: {
        required: true,
        type: Boolean
      }
    }
  }
</script>