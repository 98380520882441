var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InputTel",
      class: _vm.hasChanged
        ? _vm.isCorrect
          ? "InputTel--valid"
          : "InputTel--error"
        : ""
    },
    [
      _c("div", { staticClass: "InputTel-container" }, [
        _c(
          "div",
          { staticClass: "Form-row" },
          [
            _c("input", {
              staticClass: "InputTel-input Form-input",
              class: {
                "InputTel-input--valid": _vm.hasChanged && _vm.isCorrect,
                "InputTel-input--error": _vm.hasChanged && !_vm.isCorrect,
                hasValue: this.inputValue.length
              },
              attrs: {
                type: "text",
                name: "InputTel-" + _vm.answer.id,
                id: "InputTel-" + _vm.answer.id,
                required: _vm.answer.required
              },
              on: { keyup: _vm.isCorrectValue }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "Form-label",
                attrs: { for: "InputTel-" + _vm.answer.id }
              },
              [_vm._v(_vm._s(this.answerLabel))]
            ),
            _vm._v(" "),
            _c("FormInputIcon", { attrs: { isLoading: false } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "InputTel-message" }, [
          _vm._v(
            "Le numéro de téléphone ne peut pas être vide. Il doit être au format 0123456789 / +3323456789"
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }