
// Mobile menu toggle function
function init() {
  const body = document.body
  const menuButton = document.querySelector('[data-menuMobile-trigger]')
  const menuOverlay = document.querySelector('[data-menuMobile-overlay]')
  const serchButton = document.querySelector('[data-search-trigger]')

  if (!menuButton || !menuOverlay) return

  const searchTrigger = document.querySelector('[data-search-trigger]')

  menuButton.addEventListener('click', () => {
    
    menuButton.getAttribute('aria-expanded') === "true" ? menuButton.setAttribute('aria-expanded', "false") : menuButton.setAttribute('aria-expanded', "true")
    
    searchTrigger.setAttribute('aria-expanded', 'false')
    
    if(body.classList.contains('menu-open--mobile')) {
      body.classList.remove('menu-open--mobile')
      if(serchButton.classList.contains('disabled')) {
        serchButton.classList.remove('disabled')
      }
    }else {
      const scrollValue = window.scrollY
      let scrollPos 
      
      if (window.matchMedia("(min-width: 1100px)").matches) {
        scrollPos = 126
      } else {
        scrollPos = 76
      }
      
      if(scrollValue < scrollPos) {
        window.scrollTo({
          top: scrollPos,
          behavior: 'smooth'
        });
      }
      
      setTimeout(() => {
        body.setAttribute('data-header-sticky', 'true')
        body.classList.add('menu-open--mobile')
      }, 100);
    }
    
    if(body.classList.contains('menu-open--services') || body.classList.contains('menu-open--search')) {
      body.classList.remove('menu-open--services')
      body.classList.remove('menu-open--search')
      
      if(body.classList.contains('menu-open--mobile')) {
        body.classList.remove('menu-open--mobile')
        
        if(serchButton.classList.contains('disabled')) {
          serchButton.classList.remove('disabled')
        }
      }
    }

  })

  menuOverlay.addEventListener('click', () => {
    body.classList.remove('menu-open--mobile')
  })
}

export default { init }