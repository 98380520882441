// ------
// Styles
// ------

import "normalize.css/normalize.css";
import "./styles/index.scss";

// ---------
// SVG icons
// ---------

import "./icons";

// -------
// Scripts
// -------

// -------
// Libraries
// -------

import "what-input"
import vueAppHandler from './components/SPFPlayer/lib/vueAppHandler';

// -------
// Regular imports
// -------

import accordeon from "./components/Accordeon/Accordeon"
import cardSlider from "./components/CardSlider/CardSlider"
import form from "./components/Form/Form"
import fixedBanner from "./components/FixedBanner/FixedBanner"
import header from "./components/Header/Header"
import hero from "./components/Hero/Hero"
import heroCards from "./components/HeroCards/HeroCards"
import listArticle from "./components/ListArticle/ListArticle"
import map from "./components/Map/Map"
import modals from "./js/modals"
import menuDesktop from "./components/Menus/Menudesktop/Menudesktop"
import menuMobile from "./components/Menus/Menumobile/Menumobile"
import menuMobileTrigger from "./components/Menus/Menumobiletrigger/Menumobiletrigger"
import menuService from "./components/Menus/Menuservices/Menuservices"
import popin from "./embed/popin/popin"
import Search from "./components/Search/Search"
import searchResult from "./components/SearchResult/SearchResult"
import sticky from "./js/sticky"
import TPEForm from "./components/TPEForm/vueFormAppHandler"
import TestForm from "./components/TestForm/vueFormAppHandler"
import "./js/chatDimelo"
import tracking from "./js/tracking"

// Run init function for all files when document is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init);
} else {
  init();
}

function init() {
  accordeon.init()
  cardSlider.init()
  form.init()
  fixedBanner.init()
  header.init()
  hero.init()
  heroCards.init()
  listArticle.init()
  map.init()
  modals.init()
  menuDesktop.init()
  menuMobile.init()
  menuMobileTrigger.init()
  menuService.init()
  popin.init()
  Search.init()
  searchResult.init()
  TestForm.init()
  TPEForm.init()
  vueAppHandler.init()
  sticky.init()
  tracking.init()
}

