var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Progression",
      class: _vm.progressionIndex === 0 ? "Progression--hidden" : ""
    },
    [
      _c(
        "transition",
        { attrs: { name: "grow", mode: "out-in", appear: "" } },
        [
          _c("div", {
            staticClass: "Progression-tracker",
            style: { width: this.trackerWidth }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }