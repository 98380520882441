import { render, staticRenderFns } from "./InputSelect.vue?vue&type=template&id=16ae0486&"
import script from "./InputSelect.vue?vue&type=script&lang=js&"
export * from "./InputSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/production/spf-sexosafe-ezplatform/src/AppBundle/Resources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16ae0486')) {
      api.createRecord('16ae0486', component.options)
    } else {
      api.reload('16ae0486', component.options)
    }
    module.hot.accept("./InputSelect.vue?vue&type=template&id=16ae0486&", function () {
      api.rerender('16ae0486', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TestForm/components/InputSelect/InputSelect.vue"
export default component.exports