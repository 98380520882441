import { store } from './Map'
import { updateStructureList, initDropdown, openMap } from './MapList'
import { MapButtonHoverInit } from './MapButtonHover'
import { axiosClient } from '../../js/axiosClient'

export function fetchStructures(params) {

  // Url Object for the AJAX call
  const url = new URL('/structure/search', window.location.origin);

  const mapFormHome = document.querySelector('[data-context-home]')

  // Parameters of the first Result of the request 
  let parameters = {}

  if (params) {
    parameters.latitude = params.latitude
    parameters.longitude = params.longitude
    parameters.service = params.service
    updateStructureList()
    MapButtonHoverInit()
  }else{
    parameters.latitude = store.startLocation.lat(),
    parameters.longitude = store.startLocation.lng(),
    parameters.service = store.service
  }

  // Used to display the introduction page or the search results page depending on URL parameters
  url.search = new URLSearchParams(parameters)
  history.pushState(null, null, url.search);

  // Necessary header for the ajax request
  const headers = { 
    'Content-Type': 'application/json',
  };

  if(!mapFormHome) {

    // Call axios to get the center list 
    axiosClient.post(url.href, parameters, headers)
    .then(response => {
      store.structures = mapResults(response.data.docs) // Map the results
      updateStructureList()
      MapButtonHoverInit()
      initDropdown()
      openMap()
    }, (error) => {
      console.log(error);
    });
  }
    
}

// Get the results datas and recreate the correct JSON
function mapResults(results) {
  
  const children = []
  
  Object.entries(results).forEach(([key, value]) => {
    let entries = {}
    Object.entries(value).forEach(([subKey, subValue]) => {

      if(subKey === 'noms') Object.assign(entries, {name: subValue});
      if(subKey === 'facebook') Object.assign(entries, {facebook: subValue});
      if(subKey === 'horaires') Object.assign(entries, {infos: subValue});
      if(subKey === 'codepst') Object.assign(entries, {postcode: subValue});
      if(subKey === 'ville') Object.assign(entries, {city: subValue});
      if(subKey === 'adresse') Object.assign(entries, {address1: subValue});
      if(subKey === 'tel1') Object.assign(entries, {phone_number: subValue});
      if(subKey === 'web') Object.assign(entries, {web: subValue});
      if(subKey === 'geolat') Object.assign(entries, {latitude: parseFloat(subValue)});
      if(subKey === 'geolong') Object.assign(entries, {longitude: parseFloat(subValue)});
      if(subKey === 'distance'){Object.assign(entries, {distance: parseFloat(subValue)});} 
      if(subKey === 'refactiv'){
        const mappedArray = mapStructureType(Object.values(subValue))
        const flattenArray = removeDuplicates(mappedArray)
        let structure_type = {}
        Object.assign(structure_type, {label : flattenArray});
        Object.assign(entries, {structure_type: structure_type});
      }
    });
    children.push(entries)
  });

  return children
}

// Remove duplicate entries in an array
function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

// Set the correct structure type label depending on their ID
function mapStructureType (arr) {
  let mappedArray = []
  arr.forEach((element, index, arr) => {
    if(element == 9) mappedArray.push('Service hospitalier VIH')
    else if(element == 13) mappedArray.push('Centre de dépistage')
    else if(element == 112) mappedArray.push('Consultation PrEP')
    else if(element == 75 || element == 77) mappedArray.push('Associations LGBT+') 
    else if(element == 130 || element == 138) mappedArray.push('Test rapide en CeGIDD')
  });
  
  return mappedArray
}
