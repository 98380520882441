function init() {
  prepFormStart()
  tpeFormStart()
  jeFaisLeTestFormStart()
  chatStart()
  phoneNumberClick()
  downloadLink()
  clickBlockFooter()
  clickBlockMiddlePage()
  searchResultClick()
  menu()
  player()
}
//A CHAQUE FORMULAIRE PrEP INITIE
//Renvoie le nom du formualire, ici PrEP
function prepFormStart() {
  const externalLinks = document.querySelectorAll('[data-tracking="prep-start"]')
  externalLinks.forEach((externalLink) => {
    externalLink.addEventListener('click', (e) => {
      return tC.event.prepFormStart(this, {'formName':'PrEP'});
    })
  })
}


// A CHAQUE FORMULAIRE TPE INITIE
//Renvoie le nom du formualire, ici TPE
function tpeFormStart() {
  const externalLinks = document.querySelectorAll('[data-tracking="tpe-start"]')
  externalLinks.forEach((externalLink) => {
    externalLink.addEventListener('click', (e) => {
      return tC.event.tpeFormStart(this, {'formName':'TPE'});
    })
  })
}

//A CHAQUE FORMULAIRE  "JE FAIT LE TEST" INITIE
function jeFaisLeTestFormStart() {
  const externalLinks = document.querySelectorAll('[data-tracking="je-fais-le-test-start"]')
  externalLinks.forEach((externalLink) => {
    externalLink.addEventListener('click', (e) => {
      return tC.event.jeFaisLeTestFormStart(this, {'formName':'Je fais le test'});
    })
  })
}


//TODO: waiting response of dimelos contributor to initialize new attribute on chat button
// //A CHAQUE CHATS INITIES
// // Renvoie le nom du chat initié
// function chatStart() {
//   const chatLinks = document.querySelectorAll('[data-tracking="button-chat"]')
//   const chatBlock = document.querySelector(".dimelo_chat_button")
//   const chatId = chatBlock.getAttribute('id')
//   chatLinks.forEach((chatLink) => {
//     chatLink.addEventListener('click', (e) => {
//       return tC.event.chatStart(this, {'chatName': chatId})
//     })
//   })
// }

//A CHAQUE CLIC SUR LE NUMERO DE TELEPHONE DANS L'ANNUAIRE
// Renvoie le type de la page, ici annuaire
function phoneNumberClick() {
  const phoneLinks = document.querySelectorAll('[data-tracking="button-phone"]')
  phoneLinks.forEach((phoneLink) => {
    phoneLink.addEventListener('click', (e) => {
      return tC.event.phoneNumberClick(this, {'pageType': 'annuaire'})
    })
  })
}

//A CHAQUE UTILISATION D'UN MOTEUR DE RECHERCHE INTERNE
//Renvoie le nom du moteur de recherche utilisé, ici header
//A CHAQUE CLIC SUR UN RESULTAT DE RECHERCHE DU MOTEUR DE RECHERCHE
//Renvoie le nom du résultat cliqué
//Renvoie les mots-clés recherchés
function searchResultClick() {
  const searchForms = document.querySelectorAll('form[name="Search-form"]')
  searchForms.forEach((searchForm) => {
    searchForm.addEventListener('submit', (e) => {
      return tC.event.searchEngine(this, {
        'usedSearchEngine': 'header',
        'pageName': document.title
      })
    })
  })

  const searchResults = document.querySelectorAll('[data-tracking="search-results"]')
  var url = new URL(window.location.href);
  var searchedText = url.searchParams.get("search");
  searchResults.forEach((searchResult) => {
    searchResult.addEventListener('click', (e) => {
      return tC.event.searchResultClick(this, {
        'clickedSearchResult': searchResult.innerText,
        'searchedKeyword': searchedText
      })
    })
  })
}

//A CHAQUE TELECHARGEMENT D'UN DOCUMENT
//Renvoie le nom du document téléchargé
function downloadLink() {
  const downloadLinks = document.querySelectorAll('[data-tracking="button-download"]')
  downloadLinks.forEach((downloadLink) => {
    const fileName = downloadLink.querySelector('span').innerText
    downloadLink.addEventListener('click', (e) => {
      return tC.event.documentDownload(this, {'downloadedDocumentName': fileName})
    })
  })
}

//A CHAQUE CLIC SUR LE BLOC "REBONDS EN BAS DE PAGE"
//Renvoie le nom du bloc cliqué, ici Rebonds en bas de page
function clickBlockFooter() {
  const blocksFooter = document.querySelectorAll('[data-tracking="block-footer"]')
  blocksFooter.forEach((blockFooter) => {
    const results = blockFooter.querySelectorAll('.Card, .ListArticle, .ArticleFooter-tags')
      results.forEach((result) => {
        result.addEventListener('click', (e) => {
        return tC.event.clickBlockFooter(this, {'blockName': 'Rebonds en bas de page'});
      })
    })
  })
}

//A CHAQUE CLIC SUR LE BLOC "REBONDS EN COEUR DE PAGE"
//Renvoie le nom du bloc cliqué, ici Rebonds en coeur de page
function clickBlockMiddlePage() {
  const blocks = document.querySelectorAll('[data-tracking="block-middle-page"]')
  blocks.forEach((block) => {
    block.addEventListener('click', (e) => {
      return tC.event.clickBlockMiddlePage(this, {'blockName':'Rebonds en coeur de page'});
    })
  })
}

//A CHAQUE CLIC SUR UN MENU DE NIVEAU 1
//Renvoie le nom du menu de niveau 1 cliqué
//A CHAQUE CLIC SUR UN MENU DE NIVEAU 2
//Renvoie le nom du menu de niveau 2 cliqué
//Renvoie le nom du menu de niveau 1 parent du menu de niveau 2 cliqué

function menu() {
  const menuItems = document.querySelectorAll('[data-tracking="menu-item"]')

  menuItems.forEach((menuItem) => {
    const menuRubricLink = menuItem.querySelector('[data-tracking="menu-link"]')
    menuRubricLink.addEventListener('click', (e) => {
      return tC.event.menuLevelOne(this, {'menuLevel1': menuRubricLink.innerText})
    })

    const menuLinkSubrubrics = menuItem.querySelectorAll('[data-tracking="menu-sublink-subrubric"]')
    menuLinkSubrubrics.forEach((menuLinkSubrubric) => {
      menuLinkSubrubric.addEventListener('click', (e) => {
        return tC.event.menuLevelTwo(this, {
          'menuLevel2': menuLinkSubrubric.innerText,
          'parentMenuLevel1': menuRubricLink.innerText
        })
      })
    })
  })
}

function player() {

  const players = document.querySelectorAll('[data-spf-player]')

  if (!players.length) return

  let ATTag = new ATInternet.Tracker.Tag();

  const canPlayTrackingFunction = canPlayTracking.bind(null, ATTag)
  const playTrackingFunction = playTracking.bind(null, ATTag)
  const pauseTrackingTrackingFunction = pauseTracking.bind(null, ATTag)

  const config = {
    attributes: true,
    attributeOldValue : true
  }

  // observer
  const mediaChangeCallback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-current-media-index') {
        setPlayerTracking(
            mutation.target.closest('[data-spf-player]'),
            canPlayTrackingFunction,
            playTrackingFunction,
            pauseTrackingTrackingFunction,
            ATTag,
            mutation.oldValue
        )
      }
    }
  };

  players.forEach(player => {
    setPlayerTracking(player, canPlayTrackingFunction, playTrackingFunction, pauseTrackingTrackingFunction)

    const playerWidget = player.querySelector(".SPFPlayer-player")
    const observer = new MutationObserver(mediaChangeCallback);
    observer.observe(playerWidget, config);
  })
}

function setPlayerTracking(
    player,
    canPlayTrackingFunction,
    playTrackingFunction,
    pauseTrackingTrackingFunction,
    ATTag = null,
    previousMediaIndex = null
) {
  const mediaTag = player.querySelector(".SPFPlayer-mediaPlayer")

  // Remove previous media from ATTag
  if(ATTag !== null && previousMediaIndex !== null) {
    const playerData = JSON.parse(player.getAttribute('data-spf-player'))
    const previousMediaData = playerData.medias[previousMediaIndex]
    ATTag.richMedia.remove(previousMediaData.id)
  }

  // Remove old event
  mediaTag.removeEventListener('canplay', canPlayTrackingFunction)
  mediaTag.removeEventListener('play', playTrackingFunction)
  mediaTag.removeEventListener('pause', pauseTrackingTrackingFunction)

  // Add new one with updated data
  mediaTag.addEventListener('canplay', canPlayTrackingFunction)
  mediaTag.addEventListener('play', playTrackingFunction)
  mediaTag.addEventListener('pause', pauseTrackingTrackingFunction)
}

function canPlayTracking(ATTag, event) {

  const player = event.target.closest('[data-spf-player]')
  const playerWidget = player.querySelector(".SPFPlayer-player")
  const playerData = JSON.parse(player.getAttribute('data-spf-player'))
  const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

  ATTag.richMedia.add({
    mediaType: playerData.options.mediaType !== undefined ? playerData.options.mediaType : 'video',
    playerId: currentMediaData.id,
    mediaLabel: currentMediaData.title,
    refreshDuration: {
      0: 5,
      1: 15,
      5: 30,
      10: 60
    },
    duration: currentMediaData['duration_in_second'],
    isEmbedded: false,
    webdomain: ''
  })
}

function playTracking(ATTag, event) {
  const player = event.target.closest('[data-spf-player]')
  const playerWidget = player.querySelector(".SPFPlayer-player")
  const playerData = JSON.parse(player.getAttribute('data-spf-player'))
  const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

  ATTag.richMedia.send({
    action: 'play',
    playerId: currentMediaData.id,
    mediaLabel: currentMediaData.title
  })
}

function pauseTracking(ATTag, event) {
  const player = event.target.closest('[data-spf-player]')
  const playerWidget = player.querySelector(".SPFPlayer-player")
  const playerData = JSON.parse(player.getAttribute('data-spf-player'))
  const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

  ATTag.richMedia.send({
    action: 'pause',
    playerId: currentMediaData.id,
    mediaLabel: currentMediaData.title
  })
}

export default { init }