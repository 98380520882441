const BANNER_SELECTOR = '[data-fixedbanner]'
const BANNER_BUTTON_SELECTOR = '[data-fixedbanner-trigger]'
const HERO_SELECETOR = '[data-hero]'

const banner = document.querySelector(BANNER_SELECTOR)
const bannerButton = document.querySelector(BANNER_BUTTON_SELECTOR)

const hero = document.querySelector(HERO_SELECETOR)
let isVisible = false

function init() {
  
  if(!banner) return 
  
  initBanner()
    
} 
  
function initBanner() {
    const bannerHomepageAttribute = banner.getAttribute('data-fixedbanner-homepage')
    
  if(!bannerHomepageAttribute && !hero) {
    showBanner()
  } else {
    initHomepageBanner()
  }

  initButtonBehavior()
  
}

function initButtonBehavior() {
  bannerButton.addEventListener('click', () => {
    hideBanner()
  })
}

function showBanner() {
  banner.setAttribute('aria-hidden', 'false')
}

function hideBanner() {
  banner.setAttribute('aria-hidden', 'true')
}

function initHomepageBanner() {
  const heroPosition = hero.offsetTop
  const heroHeight = hero.offsetHeight
  let scroll = window.scrollY
  
  // init on load
  if(scroll > heroHeight + heroPosition) {
    showBanner()
    isVisible = true
  }
  
  window.addEventListener('scroll' , () =>{
    
    scroll = window.scrollY
    if(scroll > heroHeight + heroPosition) {
      if(!isVisible) {
        throttle(showBanner(), 50);
        isVisible = true
      }
    }
  })
}

function throttle (callback, limit) {
  var tick = false;
  return function () {
    if (!tick) {
      callback.call();
      tick = true;
      setTimeout(function () {
        tick = false;
      }, limit);
    }
  }
}

export default { init }