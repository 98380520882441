<template>
  <div class="TestForm" :class="{'TestForm--progression': hasProgression}">
    
    <PreviousButton
      v-if="componentId > 0"
      @PreviousButton::previous-action="previousAction"
    />

    <div class="TestForm-container">
      
      <transition name="fade" mode="out-in">
        <component
          :is="componentType"
          :index="componentId"
          :questions="questions"
          :result="result"
          :hasProgression="hasProgression"
          @DynamicQuestion::set-data="setData"
          @DynamicQuestion::set-action="setAction"
          @Question::set-data="setData"
          @Question::set-action="setAction"
        ></component>
      </transition>      

      <div class="container container--small container--isStatic" v-if="hasProgression" data-progression> 
        <div class="container-container">
          <div class="container-inner">
            <div class="container-content">
              <div>
                <Progression
                  :progressionIndex="progressionIndex"
                  :progressionlength="progressionlength"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DynamicQuestion from "./components/DynamicQuestion/DynamicQuestion.vue"
import PreviousButton from "./components/PreviousButton/PreviousButton.vue"
import TestResult from "./components/TestResult/TestResult.vue"
import Progression from './components/Progression/Progression.vue'
import axios from "axios";

export default {
  name: "Form",
  components: {
    DynamicQuestion,
    PreviousButton,
    TestResult,
    Progression
  },
  props: {
    questions: {
      required: true,
      type: Array,
    },
    result: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      componentId: 0,
      componentType: "DynamicQuestion",
      dataStorage: [],
      actionStorage: [],
    }
  },
  computed: {

    progression() {
      if(this.questions){
        if(this.questions[this.componentId]){

          let progression = this.questions[this.componentId].progression || this.questions[this.componentId].answers.progression

          if(progression){
            return {
              index: parseInt(progression[0]),
              length: parseInt(progression[progression.length - 1])
            }
          }
        }
      }
    },
    progressionIndex() {
      if(this.questions){
        if(this.questions[this.componentId]){

          let progression = this.questions[this.componentId].progression || this.questions[this.componentId].answers.progression

          if(progression){
            return parseInt(progression[0])
          }
        }
      }
    },
    progressionlength() {
      if(this.questions){
        if(this.questions[this.componentId]) {

          let progression = this.questions[this.componentId].progression || this.questions[this.componentId].answers.progression

          // this.questions[this.componentId].answers.progression is probably not used if it isn't check the JSON to remove it at question8

          if(progression){
            return parseInt(progression[progression.length - 1])
          }
        }
      }
    },
    hasProgression() {

      let isOk = false

      if(this.questions[this.componentId].progression !== undefined) {
        isOk = true
      }

      if(this.questions[this.componentId].answers !== undefined){
        if(this.questions[this.componentId].answers.progression !== undefined){
          isOk = true
        }
      }

      if(this.componentType === 'TestResult'){
        isOk = false
      }

      return isOk
    }
  },
  methods: {
    setData(data) {
      // create a copy of the object so it does not have any reference anymore
      // The problem was that every entry of storage array was updating by the new reference at every call
      // JSON copy method was used because {...data} wasn't working
      // push in dataStorage array to keep it in case we need those data
      this.dataStorage.push(JSON.parse(JSON.stringify(data)))
      this.setLocalStorageData('test_data', JSON.stringify(this.dataStorage))


    },
    setAction(action) {
      // only update the actionStorage array and localStorage if componentType is a question
      this.actionStorage.push(JSON.parse(JSON.stringify(action)))
      this.componentType = action.componentType
      this.componentId = action.id
      localStorage.setItem('test_action', JSON.stringify(this.actionStorage))
    },
    previousAction() {

      // remove last entry of dataStorage and keep it in a const in case we need it
      const lastDataStorageEntry = this.dataStorage.splice(-1)
      localStorage.setItem('test_data', JSON.stringify(this.dataStorage))

      // remove last entry of actionStorage and keep it in a const in case we need it
      const lastActionStorageEntry = this.actionStorage.splice(-1)
      localStorage.setItem('test_action', JSON.stringify(this.actionStorage))

      const lastAction = lastActionStorageEntry[0]

      this.componentType = 'DynamicQuestion'
      this.componentId = parseInt(lastAction.lastActionId)

    },
    setLocalStorageData(key, value) {
      localStorage.setItem(key, value)
    },
    resetForm() {
      this.componentType = "DynamicQuestion"
      this.componentId = 0
      this.dataStorage = []
      this.actionStorage = []
      localStorage.removeItem('test_data');
      localStorage.removeItem('test_action');
    },
    resetFormOnClose() { // The button that trigger the closing is out of vue
      const closeButtons = document.querySelectorAll('[data-form-reset]')

      if(!closeButtons.length) return

      closeButtons.forEach(button => {
        button.addEventListener('click', () => {
          this.resetForm()
        })
      });
    },
  },
  beforeMount() {
    // this.resetForm() // Here to reset values on load
    // this.resetFormOnClose() // Here to reset values on popin close
  },
}
</script>