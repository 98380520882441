var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "TestForm",
      class: { "TestForm--progression": _vm.hasProgression }
    },
    [
      _vm.componentId > 0
        ? _c("PreviousButton", {
            on: { "PreviousButton::previous-action": _vm.previousAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "TestForm-container" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _c(_vm.componentType, {
                tag: "component",
                attrs: {
                  index: _vm.componentId,
                  questions: _vm.questions,
                  result: _vm.result,
                  hasProgression: _vm.hasProgression
                },
                on: {
                  "DynamicQuestion::set-data": _vm.setData,
                  "DynamicQuestion::set-action": _vm.setAction,
                  "Question::set-data": _vm.setData,
                  "Question::set-action": _vm.setAction
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasProgression
            ? _c(
                "div",
                {
                  staticClass: "container container--small container--isStatic",
                  attrs: { "data-progression": "" }
                },
                [
                  _c("div", { staticClass: "container-container" }, [
                    _c("div", { staticClass: "container-inner" }, [
                      _c("div", { staticClass: "container-content" }, [
                        _c(
                          "div",
                          [
                            _c("Progression", {
                              attrs: {
                                progressionIndex: _vm.progressionIndex,
                                progressionlength: _vm.progressionlength
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }