var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "DynamicQuestion container container--small container--isStatic"
    },
    [
      _c("div", { staticClass: "container-container" }, [
        _c("div", { staticClass: "container-inner" }, [
          _c(
            "div",
            { staticClass: "container-content" },
            [
              _c(
                "transition-group",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c(
                    "div",
                    {
                      key: _vm.questions[_vm.index].title,
                      staticClass: "DynamicQuestion-container",
                      class: _vm.autocompleteQuestion
                        ? "DynamicQuestion-container--extraPadding"
                        : ""
                    },
                    [
                      _c("div", { staticClass: "DynamicQuestion-header" }, [
                        _c(
                          "h3",
                          {
                            staticClass: "DynamicQuestion-title TPEForm-title"
                          },
                          [_vm._v(_vm._s(_vm.questions[_vm.index].title))]
                        ),
                        _vm._v(" "),
                        _vm.questions[_vm.index].text
                          ? _c(
                              "p",
                              {
                                staticClass: "DynamicQuestion-text",
                                class: {
                                  "DynamicQuestion-text--bold":
                                    _vm.questions[_vm.index].id == 0
                                }
                              },
                              [_vm._v(_vm._s(_vm.questions[_vm.index].text))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questions[_vm.index].additionalInfos
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "DynamicQuestion-text DynamicQuestion-text--bold"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.questions[_vm.index].additionalInfos
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.questions[_vm.index].hasRequired
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "DynamicQuestion-text DynamicQuestion-text DynamicQuestion-text--small"
                              },
                              [_vm._v("* champs obligatoires")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "DynamicQuestion-answers",
                          class: {
                            "DynamicQuestion-answers--row":
                              _vm.questions[_vm.index].style === "row"
                          }
                        },
                        [
                          _vm.questions[_vm.index].id == 2
                            ? _c(
                                "div",
                                [
                                  _c("Optin", {
                                    attrs: {
                                      answer:
                                        _vm.questions[_vm.index].answers[0]
                                    },
                                    on: {
                                      "Optin::isChecked": _vm.setDisabledButton
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._l(_vm.questions[_vm.index].answers, function(
                                answer,
                                index
                              ) {
                                return _c(answer.type, {
                                  key: "input-" + index,
                                  tag: "component",
                                  attrs: {
                                    type: answer.type,
                                    answer: answer,
                                    questions: _vm.questions,
                                    question: _vm.questions[index],
                                    index: index
                                  },
                                  on: {
                                    "Optin::set-data": _vm.setData,
                                    "Optin::set-action": _vm.setAction,
                                    "InputAddress::set-type": _vm.setType,
                                    "InputAddress::set-data": _vm.setData,
                                    "InputText::set-data": _vm.setData,
                                    "InputMail::set-data": _vm.setData,
                                    "InputTel::set-data": _vm.setData,
                                    "InputRadio::set-action": _vm.setAction,
                                    "InputRadio::set-data": _vm.setData,
                                    "InputSelect::set-data": _vm.setData,
                                    "InputCheckbox::set-data":
                                      _vm.setMultipleData,
                                    "InputSelect::set-action": _vm.setAction
                                  }
                                })
                              }),
                          _vm._v(" "),
                          _vm.questions[_vm.index].nextButton
                            ? _c(
                                "div",
                                {
                                  staticClass: "DynamicQuestion-actions",
                                  class: {
                                    "DynamicQuestion-actions--row":
                                      _vm.questions[_vm.index].style === "row",
                                    "DynamicQuestion-actions-transitionQuestion":
                                      _vm.questions[_vm.index].id == 2
                                  }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "DynamicQuestion-answer Button Button--black",
                                      attrs: {
                                        disabled:
                                          _vm.isDisabled || _vm.isSkipable
                                      },
                                      on: { click: _vm.nextAction }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "Button-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.questions[_vm.index]
                                                .nextButton.label
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.questions[_vm.index].skipButton
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "DynamicQuestion-answer Button Button--black",
                                          class: {
                                            "DynamicQuestion-answer--inline":
                                              _vm.questions[_vm.index].id == 2
                                          },
                                          on: { click: _vm.skipAction }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "Button-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.questions[_vm.index]
                                                    .skipButton.label
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }