const POPIN_SELECTOR = '[data-popin]'
const POPIN_BUTTONS_SELECTOR = '[data-popin-trigger]'
const HEADER_SELECTOR = '[data-header]'

const buttons = document.querySelectorAll(POPIN_BUTTONS_SELECTOR)
const header = document.querySelector(HEADER_SELECTOR)
const popin = document.querySelector(POPIN_SELECTOR)
const body = document.querySelector('body')

let offsetValue 
let isSticky = body.getAttribute('data-header-sticky') === "true"
let scroll = window.scrollY

function init() {
  
  if (!buttons.length || !popin) return
  initPopin()  
}

function initPopin() {
  initPopinStyles()
  updatePopinStyles()
  togglePopin()
}

function initPopinStyles() {
  
  const headerHeight = header.offsetHeight
  const headerStyle = window.getComputedStyle(header)
  const headerMargin = parseFloat(headerStyle['marginTop']) + parseFloat(headerStyle['marginBottom'])
  const headerTotalHeight = headerHeight + headerMargin
  const isSticky = (header.classList.contains('Header--sticky') === true)

  let popinHeight 
  let popinTop 

  popinHeight = `calc(100vh - ${headerTotalHeight}px)`
  popinTop = `${headerTotalHeight}px`
  
  if(!isSticky){
    popinHeight = `calc(100vh - ${headerTotalHeight - scroll}px)`
    popinTop = `${headerTotalHeight - scroll}px`
  }

  popin.style.height = popinHeight
  popin.style.top = popinTop
}

function updatePopinStyles() {
  window.addEventListener('scroll' , () =>{
    scroll = window.scrollY
    throttle(initPopinStyles(), 10);
  })
}

function togglePopin() {
  
  buttons.forEach(button => {
    button.addEventListener('click', (e) => {
      
      const scrollValue = window.scrollY
      let scrollPos 
      let timing 

      if (window.matchMedia("(min-width: 1100px)").matches) {
        scrollPos = 140
      } else {
        scrollPos = 80
      }
      
      if(scrollValue < scrollPos) {
        timing = 500
        window.scrollTo({
          top: scrollPos,
          behavior: 'smooth'
        });
      }else {
        timing = 100
      }
      
      body.setAttribute('data-header-sticky', 'true')      

      const isOpen = (button.getAttribute('aria-expanded') === 'true')
      
      if(isOpen){
        closePopin(buttons)
      }else {
        setTimeout(() => {
          openPopin(buttons)
        }, 500);
      }

    })
  });
}

function openPopin (buttons) {

  initPopinStyles()

  popin.setAttribute('aria-hidden', 'false')
  body.classList.add('popin-open')
  
  buttons.forEach(button => {
    button.setAttribute('aria-expanded', 'true')
  });
}

function closePopin (buttons) {
  popin.setAttribute('aria-hidden', 'true')
  body.classList.remove('popin-open')

  buttons.forEach(button => {
    button.setAttribute('aria-expanded', 'false')
  });
}

function throttle (callback, limit) {
  var tick = false;
  return function () {
    if (!tick) {
      callback.call();
      tick = true;
      setTimeout(function () {
        tick = false;
      }, limit);
    }
  }
}



export default { init }





