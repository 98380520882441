class OpacityHover {

    constructor(containers) {
      this.containers = containers
      this.elementSelector = '[data-hover-opacity]'
      this.opacityHigh = 1 
      this.opacityLow = 0.5 
    }
  
    // Check if values are correct and launch init
    init() {
      if(!this.containers.length) return
      this.initHover(this.containers)
    }
  
    // Init all function 
    initHover(containers) {
      containers.forEach(container => {
        
        const elements = this.getHoverElements(container)
  
        this.addMouseOverEvent(elements)
        this.addMouseLeaveEvent(elements)
      });
    }
    
    // Get all Elements that can will benefitof the hover effect in the container
    getHoverElements(container) {
      return container.querySelectorAll(this.elementSelector)
    }
  
    // add mouseover event
    addMouseOverEvent(elements){
      elements.forEach(element => {
        element.addEventListener('mouseover', (e) => {
          this.fadeElements(elements)
          this.showElement(element)
        })
      });
    }
  
    // Add mouseleave event
    addMouseLeaveEvent(elements) {
      elements.forEach(element => {
        element.addEventListener('mouseleave', (e) => {
          this.unfadeElements(elements)
        })
      });
    }
  
    // Make the current hovered element at 1 opacity
    showElement(element) {
      element.style.opacity = this.opacityHigh
    }
    
    // Reduce opacity of all other elements
    fadeElements(elements) {
      elements.forEach(element => {
        element.style.opacity = this.opacityLow
      });
    }
    
    // MAke all elements opacity 1
    unfadeElements(elements) {
      elements.forEach(element => {
        element.style.opacity = this.opacityHigh
      });
    }
  
  }
  
  export default OpacityHover 