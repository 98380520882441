import axios from 'axios'

let axiosClient = axios.create();

if (window.location.hostname === 'localhost') {
  axiosClient.defaults.baseURL = 'http://localhost:5000/'
}

axiosClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export { axiosClient }
