<template>
  <div class="InputText" :class="hasChanged ? isCorrect ? 'InputText--valid': 'InputText--error' : ''">
    <div class="InputText-container">
      <div class="Form-row">
        
        <input 
          class="InputText-input Form-input"
          :class="{ 'InputText-input--valid' : hasChanged && isCorrect , 'InputText-input--error' : hasChanged && !isCorrect, 'hasValue': this.inputValue.length}"
          type="text"
          :name="`InputText-${answer.id}`"
          :id="`InputText-${answer.id}`"
          @keyup="isCorrectValue"
          :required="answer.required"
        />

        <label class="Form-label" :for="`InputText-${answer.id}`">{{ this.answerLabel }}</label>

        <FormInputIcon
          :isLoading="false"
        />

      </div>
      <div class="InputText-message">Ce champs de formulaire Ne peut contenir que du texte. et ne peut pas être vide</div>
    </div>
  </div>
</template>

<script>

import FormInputIcon from "../FormInputIcon/FormInputIcon.vue"

export default {
  name: "InputText",
  props: {
    answer: {
      required: true,
      type: Object
    }
  },
  components: {
    FormInputIcon
  },
  data() {
    return {
      answerLabel: this.answer.required ? this.answer.label + ' *' : this.answer.label,
      regexName: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
      inputValue: '',
      hasChanged: false
    }
  },
  computed: {
    isCorrect() {
      return this.regexName.test(this.inputValue) && this.inputValue !== ''
    }
  },
  methods: {
    isCorrectValue(event) {
      this.hasChanged = true
      const input = event.target
      this.inputValue = input.value

      let toEmit = {"questionId": this.answer.id, "questionLabel": this.answer.label, "questionValue" : '', 'questionIdentifier': this.answer.identifier }
      
      if(this.isCorrect){
        toEmit.questionValue = this.inputValue
      }

      this.$emit('InputText::set-data', toEmit)
    },
  },
}
</script>