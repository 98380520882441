<template>
  <div class="Question container container--small container--isStatic ">
    <div class="container-container">
      <div class="container-inner">
        <div class="container-content">

          <transition-group name="fade" mode="out-in" >
            <div class="Question-container" :key="questions[index].title">

              <div class="Question-header">
                <h3 class="Question-title TPEForm-title">{{ questions[index].title }}</h3>
                <p v-if="questions[index].text" class="Question-text">{{ questions[index].text }}</p>
              </div>

              <div class="Question-answers">
                <button
                  class="Question-answer Button Button--white"
                  v-for="(answer, index) in questions[index].answers"
                  :key="questions[index].title"
                  @click="nextAction(answer)"
                  >
                  <span class="Button-label">{{ answer.label }}</span>
                </button>
              </div>

            </div>
          </transition-group>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: {
    questions: {
      required: true,
      type: Array,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data(){
    return {
      nextActionData: {},
      userDdata: {},
    }
  },
  methods: {
    nextAction(answer) {

      const answerCopy = {...answer}
      this.setAction(answer)
      this.setData(answerCopy)

    },
    setAction(answer) {

      if(answer.goQuestionId) {
        this.nextActionData.componentType = "Question"
        this.nextActionData.id = answer.goQuestionId
        this.nextActionData.lastActionId = this.questions[this.index].id
      }
      if(answer.goAnswerId) {
        this.nextActionData.componentType = "Result"
        this.nextActionData.id = answer.goAnswerId
        this.nextActionData.lastActionId = this.questions[this.index].id
      }

      this.$emit("Question::set-action", this.nextActionData)

    },
    setData(answer) {

      this.userDdata.questionId = answer.id
      this.userDdata.questionLabel = answer.label
      this.userDdata.questionValue = answer.value

      this.$emit("Question::set-data", this.userDdata)

    }
  }
}
</script>