import OpacityHover from '../../js/opacityHover.js'

function init() {
  
  const ARTICLE_LIST_SELECTOR = '[data-list-article]'
  const lists = document.querySelectorAll(ARTICLE_LIST_SELECTOR)
  
  if (!lists.length) return

  const opacityHoverInstance = new OpacityHover(lists)
  opacityHoverInstance.init()
} 
 
export default { init }