<template>
  <div
    class="InputAddress"
    :class="hasChanged ? isCorrect ? 'InputAddress--valid': 'InputAddress--error' : ''"
    :aria-expanded="showResponse ? 'true' : 'false'"
    data-input-address
  >
    <div class="InputAddress-container">

      <div class="Form-row">
        <input 
          v-model="inputValue"
          :name="`InputAddress-${answer.id}`"
          :id="`InputAddress-${answer.id}`"
          :required="answer.required"
          :class="{'InputAddress-input--valid' : hasChanged && isCorrect , 'InputAddress-input--error' : hasChanged && !isCorrect, 'hasValue': this.inputValue.length}"
          class="InputAddress-input Form-input"
          type="text"
          data-input-address-input
          @keyup="onDebounce"
          @blur="isCorrectValue"
        />
          
        <label class="Form-label" :for="`InputAddress-${answer.id}`">{{ this.answerLabel }}</label>
        
        <FormInputIcon
          :isLoading="isLoading"
        />

      </div>

      <div class="InputAddress-message">Ceci n'est pas une adresse valide. Veuillez choisir une des adresses suggérées (rentrer au moins quatre caractères)</div>

      <div class="InputAddress-autocomplete" :class="hasChanged ? hasResponse ? 'InputAddress-autocomplete--valid': 'InputAddress-autocomplete--error' : ''" data-autocomplete="address" :aria-hidden="showResponse ? 'false' : 'true'">
        <div class="InputAddress-autocompleteContainer">
          
          <button
            v-for="(result, index) in results"
            :key="`result-${index}`"
            class="InputAddress-autocompleteButton"
            data-autocompletion-button
            @click="updateValue" 
          >{{ result }}</button>

          <div class="InputAddress-autocomplete-error">Aucune adresse n'a été trouvée</div>
          
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { axiosClient } from '../../../../js/axiosClient'
import FormInputIcon from "../FormInputIcon/FormInputIcon.vue"

export default {
  name: "InputAddress",
  props: {
    answer: {
      required: true,
      type: Object
    }
  },
  components: {
    FormInputIcon
  },
  data() {
    return {
      answerLabel: this.answer.required ? this.answer.label + ' *' : this.answer.label,
      addresses: [],
      postCodes: [],
      results: [],
      towns: [],
      shortAdresses: [],
      town: '',
      shortAdresse: '',
      postCode: '',
      inputValue: '',
      address: '',
      autocompleteContainer: '',
      inputComponent: '', 
      component: '', 
      refCount: 0,
      debounceCount: 0,
      count: 0,
      limit: 5,
      debounce: null,
      trigger: false,
      isLoading: false,
      hasResponse: false,
      hasChanged: false,
      showResponse: false 
    }
  },
  computed: {
    isCorrect() {
      return ((this.inputValue === this.address) && this.inputValue !== '' && this.address !== '') || this.addresses.includes(this.inputValue)
    }
  },
  methods: {
    async autocomplete() {

      if(this.inputValue.length > 3) {
        
        let url = this.createQuery(this.inputValue) 

        await axiosClient.get(url)
        .then((response) => {

          this.prepareData(response)
          this.formatResponse(response)
          this.showResponse = true
          this.hasResponse = this.addresses.length ? true : false

        })
        .catch((error) => {
          console.log(error);
        });
      }else {
        this.showResponse = false
      }

    },
    createQuery(value) { // Create the query for the ajax call with base rootUrl and params 
      let rootURL = "https://api-adresse.data.gouv.fr/search/?q="  
      return rootURL + value + '&limit=' + this.limit 
    },
    prepareData(response){
      let rawDatas = response.data.features
      this.addresses = []
      this.postCodes = []
      this.towns = []
      this.shortAdresses = []

      rawDatas.forEach(data => {
        this.addresses.push(data.properties.label)
        this.postCodes.push(data.properties.postcode)
        this.towns.push(data.properties.city)
        this.shortAdresses.push(data.properties.name)
      });
    },
    formatResponse(response) { // reset the values stored and create the array used to display the different locations
      let addresses = response.data.features
      this.results = [] // reset values
      this.address = '' // reset values

      addresses.forEach(address => {
        this.results.push(address.properties.label)
      });
    },
    updateValue(event) { // update the data: inputValue and adress (that is needed to verify if the field is coorect) then launch the verification
      this.address = event.target.innerText
      this.inputValue = this.address

      const index = this.addresses.indexOf(this.address)
      this.postCode = this.postCodes[index]
      this.town = this.towns[index]
      this.shortAdresse = this.shortAdresses[index]

      this.isCorrectValue()
    },
    isCorrectValue(event) { // Verification of the data input by the user

      if(this.isCorrect) {
        this.showResponse = false
        if(this.postCode !== ''){
          this.sendData(true)
        }
      } else {
        if(this.postCode !== ''){
          this.sendData(false)
        }
        setTimeout(() => { // Don't remove or the click event of the data-autocompletion-button will not be triggered anymore 
          this.showResponse = false
        }, 100);
      }
    },
    sendData(isGood){ // send data to the parent if the data has been verified

      let toEmit = {"questionId": this.answer.id, "questionLabel": this.answer.label, "questionValue" : '', "ville" : '', "postCode": '', "questionIdentifier" : this.answer.identifier }
      if(isGood){
        toEmit.ville = this.town
        toEmit.questionValue = this.shortAdresse
        toEmit.postCode = this.postCode  
      }

      this.$emit('InputAddress::set-data', toEmit)
    },
    onDebounce() { // debounce used to prevent to many ajax calls
      
      this.hasChanged = true
      this.count ++; // Update the count by 1

      clearTimeout(this.debounce); // Clear any existing debounce event

      this.debounce = setTimeout(async () => { // Update and log the counts after .5 seconds

        this.debounceCount++; // Update the debounceCount
        this.autocomplete()

      }, 500);
    },
    setLoading(isLoading) { // Function used with the code in created to display the loader when axios is quering
      if (isLoading) {
        this.refCount++;
        this.isLoading = true;
      } else if (this.refCount > 0) {
        this.refCount--;
        this.isLoading = (this.refCount > 0);
      }
    },
  },
  mounted() {
    this.$emit("InputAddress::set-type", 'InputAddress')
  },
  created() {
    // Interceptors that handle the loading props allowing to display or not he loading icon on load 
    axiosClient.interceptors.request.use((config) => {
      this.setLoading(true);
      return config;
    }, (error) => {
      this.setLoading(false);
      return Promise.reject(error);
    });
    axiosClient.interceptors.response.use((response) => {
      this.setLoading(false);
      return response;
    }, (error) => {
      this.setLoading(false);
      return Promise.reject(error);
    });
  }
}
</script>