import Swiper, {
  Navigation,
  Pagination,
  EffectFade,
  A11y,
  Keyboard,
} from "../../../../node_modules/swiper/swiper-bundle";
import throttle from "../../../js/helpers/throttle";

const MENU_SERVICE_BUTTON = "[data-header] [data-services-trigger]";
const MENU_SERVICE_BUTTONS = "[data-services-trigger]";
const MENU_BUTTON = "[data-menuMobile-trigger]";
const SEARCH_BUTTON = "[data-search-trigger]";
const SERVICE_SLIDER = "[data-services-slider]";

const body = document.querySelector("body");
let scroll = window.scrollY;

const menuServiceButtons = document.querySelectorAll(MENU_SERVICE_BUTTONS);
const menuServiceButton = document.querySelector(MENU_SERVICE_BUTTON);
const menuButton = document.querySelector(MENU_BUTTON);
const searchButtons = document.querySelectorAll(SEARCH_BUTTON);
const searchButton = document.querySelector(SEARCH_BUTTON);
const serviceSlider = document.querySelector(SERVICE_SLIDER);

function init() {
  if (!menuServiceButtons.length) return;

  initServiceNavigation();

  if (!serviceSlider) return;

  initServiceSlider();

  if (!searchButton) return;

  initResearch();
}

function initServiceNavigation() {
  menuServiceButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();

      const scrollValue = window.scrollY;
      let scrollPos;

      if (window.matchMedia("(min-width: 1100px)").matches) {
        scrollPos = 126;
      } else {
        scrollPos = 76;
      }

      if (scrollValue < scrollPos) {
        window.scrollTo({
          top: scrollPos,
          behavior: "smooth",
        });
      }

      setTimeout(() => {
        body.setAttribute("data-header-sticky", "true");
        toggleMenu(button);
      }, 400);
    });
  });
}

function toggleMenu(button) {
  const isServiceOpen =
    body.classList.contains("menu-open--services") === false;
  const cssClass = "menu-open--services";
  toggleTriggers(isServiceOpen, cssClass, button, [searchButton]);

  if (isServiceOpen) {
    const escapeCloseHandler = (e) => {
      if (e.code === "Escape") {
        body.classList.remove("menu-open--services");
        searchButton.setAttribute("aria-expanded", "false");
        searchButton.classList.remove("disabled");
        menuServiceButtons.forEach((button) => {
          button.classList.remove("disabled");
        });
        menuButton.setAttribute("aria-expanded", "false");
        document.removeEventListener("keydown", escapeCloseHandler);
      }
    };

    document.addEventListener("keydown", escapeCloseHandler);
  }
}

function initResearch() {
  initPopinStyles();
  updatePopinStyles();

  searchButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const scrollValue = window.scrollY;
      let scrollPos;

      if (window.matchMedia("(min-width: 1100px)").matches) {
        scrollPos = 126;
      } else {
        scrollPos = 76;
      }

      if (scrollValue < scrollPos) {
        window.scrollTo({
          top: scrollPos,
          behavior: "smooth",
        });
      }

      setTimeout(() => {
        initPopinStyles();
        const isSearchOpen =
          body.classList.contains("menu-open--search") === false;
        const cssClass = "menu-open--search";
        toggleTriggers(isSearchOpen, cssClass, searchButton, [
          menuServiceButton,
        ]);

        const searchInput = document.querySelector(".Search-input");
        if (isSearchOpen) searchInput.focus();

        if (isSearchOpen) {
          const escapeCloseHandler = (e) => {
            if (e.code === "Escape") {
              body.classList.remove("menu-open--search");
              searchButton.setAttribute("aria-expanded", "false");
              searchButton.classList.remove("disabled");
              menuServiceButtons.forEach((button) => {
                button.classList.remove("disabled");
              });
              menuButton.setAttribute("aria-expanded", "false");
              document.removeEventListener("keydown", escapeCloseHandler);
            }
          };

          document.addEventListener("keydown", escapeCloseHandler);
        }
      }, 300);
    });
  });
}

function initPopinStyles() {
  const wrapper = document.querySelector("[data-search-block]");
  const header = document.querySelector("[data-header]");
  const headerHeight = header.offsetHeight;
  const headerStyle = window.getComputedStyle(header);
  const headerMargin =
    parseFloat(headerStyle["marginTop"]) +
    parseFloat(headerStyle["marginBottom"]);
  const headerTotalHeight = headerHeight + headerMargin;
  const isSticky = header.classList.contains("Header--sticky") === true;

  let containerHeight;
  let containerTop;

  containerHeight = `calc(100vh - ${headerTotalHeight}px)`;
  containerTop = `${headerTotalHeight}px`;

  if (!isSticky) {
    containerHeight = `calc(100vh - ${headerTotalHeight - scroll}px)`;
    containerTop = `${headerTotalHeight - scroll}px`;
  }

  wrapper.style.height = containerHeight;
  wrapper.style.top = containerTop;
}

function updatePopinStyles() {
  window.addEventListener("scroll", () => {
    scroll = window.scrollY;
    throttle(initPopinStyles(), 10);
  });
}

function toggleTriggers(condition, cssClass, buttonClicked, elements) {
  if (condition) {
    body.classList.add(cssClass);
    buttonClicked.setAttribute("aria-expanded", "true");
    elements.forEach((element) => {
      element.classList.add("disabled");
    });
    menuButton.setAttribute("aria-expanded", "true");
  } else {
    console.log("close");
    body.classList.remove(cssClass);
    buttonClicked.setAttribute("aria-expanded", "false");
    elements.forEach((element) => {
      element.classList.remove("disabled");
    });
    menuButton.setAttribute("aria-expanded", "false");
  }
}

function initServiceSlider() {
  let cardSpaces;
  if (window.matchMedia("(min-width: 700px)").matches) {
    cardSpaces = 40;
  } else {
    cardSpaces = 10;
  }

  const sliderOptions = {
    slidesPerView: "auto",
    spaceBetween: cardSpaces,
    slidesPerGroup: 2,
    grid: {
      rows: 2,
    },
    speed: 600,
    simulateTouch: true,
    watchSlidesProgress: true,
    watchOverflow: true,
    pagination: {
      bulletElement: "button",
      el: ".swiper-pagination",
      clickable: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    a11y: {
      enabled: false,
      prevSlideMessage: "Slide précédent",
      nextSlideMessage: "Slide suivant",
    },
    on: {
      init: function() {
        this.slides.forEach((slide) => {
          const slideLinks = slide.querySelectorAll("a");

          if (!slide.classList.contains("swiper-slide-visible")) {
            slide.setAttribute("aria-hidden", "true");
            if (slideLinks.length) {
              slideLinks.forEach((slideLink) => {
                slideLink.setAttribute("tabindex", "-1");
              });
            }
          } else {
            slideLinks.forEach((slideLink) => {
              slideLink.setAttribute("tabindex", "false");
            });
          }
        });
      },
    },
  };

  const servicesSlider = new Swiper(serviceSlider, sliderOptions);

  servicesSlider.on("transitionEnd", function() {
    this.slides.forEach((slide) => {
      const slideLinks = slide.querySelectorAll("a");

      if (!slide.classList.contains("swiper-slide-visible")) {
        slide.setAttribute("aria-hidden", "true");
        if (slideLinks.length) {
          slideLinks.forEach((slideLink) => {
            slideLink.setAttribute("tabindex", "-1");
          });
        }
      } else {
        slideLinks.forEach((slideLink) => {
          slideLink.setAttribute("tabindex", "false");
        });
      }
    });

    this.slides[this.activeIndex].focus();
  });

  servicesSlider.update();
}

export default { init };
