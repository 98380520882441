var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InputAddress",
      class: _vm.hasChanged
        ? _vm.isCorrect
          ? "InputAddress--valid"
          : "InputAddress--error"
        : "",
      attrs: {
        "aria-expanded": _vm.showResponse ? "true" : "false",
        "data-input-address": ""
      }
    },
    [
      _c("div", { staticClass: "InputAddress-container" }, [
        _c(
          "div",
          { staticClass: "Form-row" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue"
                }
              ],
              staticClass: "InputAddress-input Form-input",
              class: {
                "InputAddress-input--valid": _vm.hasChanged && _vm.isCorrect,
                "InputAddress-input--error": _vm.hasChanged && !_vm.isCorrect,
                hasValue: this.inputValue.length
              },
              attrs: {
                name: "InputAddress-" + _vm.answer.id,
                id: "InputAddress-" + _vm.answer.id,
                required: _vm.answer.required,
                type: "text",
                "data-input-address-input": ""
              },
              domProps: { value: _vm.inputValue },
              on: {
                keyup: _vm.onDebounce,
                blur: _vm.isCorrectValue,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "Form-label",
                attrs: { for: "InputAddress-" + _vm.answer.id }
              },
              [_vm._v(_vm._s(this.answerLabel))]
            ),
            _vm._v(" "),
            _c("FormInputIcon", { attrs: { isLoading: _vm.isLoading } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "InputAddress-message" }, [
          _vm._v(
            "Ceci n'est pas une adresse valide. Veuillez choisir une des adresses suggérées (rentrer au moins quatre caractères)"
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "InputAddress-autocomplete",
            class: _vm.hasChanged
              ? _vm.hasResponse
                ? "InputAddress-autocomplete--valid"
                : "InputAddress-autocomplete--error"
              : "",
            attrs: {
              "data-autocomplete": "address",
              "aria-hidden": _vm.showResponse ? "false" : "true"
            }
          },
          [
            _c(
              "div",
              { staticClass: "InputAddress-autocompleteContainer" },
              [
                _vm._l(_vm.results, function(result, index) {
                  return _c(
                    "button",
                    {
                      key: "result-" + index,
                      staticClass: "InputAddress-autocompleteButton",
                      attrs: { "data-autocompletion-button": "" },
                      on: { click: _vm.updateValue }
                    },
                    [_vm._v(_vm._s(result))]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "InputAddress-autocomplete-error" }, [
                  _vm._v("Aucune adresse n'a été trouvée")
                ])
              ],
              2
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }