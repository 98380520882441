import Swiper from '../../../node_modules/swiper/swiper-bundle.min.js'
import FadeHover from '../../js/fadeHover.js'

const SLIDER_SELECTOR = '[data-card-slider]'
const sliders = document.querySelectorAll(SLIDER_SELECTOR)

function init() {
 
  if (!sliders.length) return
  initSlider()

  const options = {
    selector : '[data-hover-fade]'
  }
  const fadeHoverInstance = new FadeHover(sliders, options)
  fadeHoverInstance.init()
} 

function initSlider() {

  let slidesGroup 
  
  if (window.matchMedia("(min-width: 1100px)").matches) {
    slidesGroup = 3
  }else {
    slidesGroup = 1
  }
  
  const sliderOptions = {
    slidesPerView: 'auto',
    slidesPerGroup: slidesGroup,
    speed: 600,
    spaceBetween: 20,
    a11y: {
      enabled: false,
      prevSlideMessage: 'slide précédente',
      nextSlideMessage: 'slide suivante',
      firstSlideMessage: "Première slide",
      lastSlideMessage: "Dernière slide",
      paginationBulletMessage: 'Aller à la slide {{index}}'
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    watchSlidesProgress: true,
    watchOverflow: true,
    pagination: {
      bulletElement:'button',
      el: '[data-card-slider-pagination]',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return `<button class="${className}"><span class="sr-only">Diapositive ${index + 1}</span></button>`
      }
    },
    on: {
      init: function () {

        const activeButton = this.el.querySelector('.swiper-pagination-bullet-active')
        activeButton.setAttribute('aria-current', 'true')

        this.slides.forEach(slide => {
          const slideLink = slide.querySelector('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            if(slideLink){
              slideLink.setAttribute('tabindex', '-1')
            }
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }
  }
  
  sliders.forEach(slider => {
    slider = new Swiper(slider, sliderOptions)

    slider.on('transitionEnd', function () {
      const allButtons = this.el.querySelectorAll('.swiper-pagination-bullet')
      allButtons.forEach(button => {
        if (button.classList.contains('swiper-pagination-bullet-active')) {
          button.setAttribute('aria-current', 'true')
        }
        else {
          button.removeAttribute('aria-current')
        }
      })

      this.slides.forEach(slide => {
        const slideLink = slide.querySelector('a')
        
        if (!slide.classList.contains('swiper-slide-visible')) {
          slide.setAttribute('aria-hidden', 'true')
          if(slideLink){
            slideLink.setAttribute('tabindex', '-1')
          }
        } else {
          slide.setAttribute('aria-hidden', 'false')
          if(slideLink){
            slideLink.removeAttribute('tabindex')
          }
        }
      })

      this.slides[this.activeIndex].focus()
    })
  });
  
}

export default { init }