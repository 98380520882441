import customSelect from '../../../../Resources/node_modules/auto-resize-custom-select/dist/customSelect';

const FORM_SELECTOR = '[data-form]'
// const SELECT_SELECTOR = '[data-select]'

const forms = document.querySelectorAll(FORM_SELECTOR)
// const selects = document.querySelectorAll(SELECT_SELECTOR)

function init() {

  if(!forms.length) return
    initForm()
  
  // if(!selects.length) return
  //   customSelect();
}

function initForm() {
  forms.forEach(form => {
    handleLabelBehavior(form)
  });
}

function handleLabelBehavior(form) {
  const rows = form.querySelectorAll('.Form-row')
  let inputsArray = []

  rows.forEach(row => {
    const inputs = row.querySelectorAll('input')
    inputsArray.push(Array.from(inputs))
  });

  inputsArray = Array.prototype.slice.call(inputsArray).flat()

  inputsArray.forEach(input => {
    input.addEventListener('change', (e) => {
      const inputValue = input.value
      if(inputValue) {
        input.classList.add('hasValue')
      }else{
        input.classList.remove('hasValue')
      }
    })
  });

}

export default { init }