<template>
  <div class="InputTel" :class="hasChanged ? isCorrect ? 'InputTel--valid': 'InputTel--error' : ''">
    <div class="InputTel-container">
      <div class="Form-row">
        <input 
          class="InputTel-input Form-input"
          :class="{ 'InputTel-input--valid' : hasChanged && isCorrect , 'InputTel-input--error' : hasChanged && !isCorrect, 'hasValue': this.inputValue.length}"
          type="text"
          :name="`InputTel-${answer.id}`"
          :id="`InputTel-${answer.id}`"
          @keyup="isCorrectValue"
          :required="answer.required"
        />

        <label class="Form-label" :for="`InputTel-${answer.id}`">{{ this.answerLabel }}</label>

        <FormInputIcon
          :isLoading="false"
        />
        
      </div>
      <div class="InputTel-message">Le numéro de téléphone ne peut pas être vide. Il doit être au format 0123456789 / +3323456789</div>
    </div>
  </div>
</template>

<script>

import FormInputIcon from "../FormInputIcon/FormInputIcon.vue"

export default {
  name: "InputTel",
  props: {
    answer: {
      required: true,
      type: Object
    }
  },
  components: {
    FormInputIcon
  },
  data() {
    return {
      answerLabel: this.answer.required ? this.answer.label + ' *' : this.answer.label,
      regexName: /^((\+)33|0)[1-9](\d{2}){4}$/i,
      inputValue: '',
      hasChanged: false
    }
  },
  computed: {
    isCorrect() {
      return this.regexName.test(this.inputValue) && this.inputValue !== ''
    }
  },
  methods: {
    isCorrectValue(event) {
      this.hasChanged = true
      const input = event.target
      this.inputValue = input.value

      let toEmit = {"questionId": this.answer.id, "questionLabel": this.answer.label, "questionValue" : '' , 'questionIdentifier' : this.answer.identifier}
      
      if(this.isCorrect){
        toEmit.questionValue = this.inputValue
      }

      this.$emit('InputTel::set-data', toEmit)
    },
  },
}
</script>