
const PODCAST_LIST_PAGE_SELECTOR = '[data-podcast-list-page]'
const PLAYER_SELECTOR = '[data-spf-player-playlist] .SPFPlayer-player--audio'

const isPodcastListPage = document.querySelector(PODCAST_LIST_PAGE_SELECTOR)

let player

let headerHeight = 111
let mainOffset // value the main's tag padding
let scroll = window.scrollY
let isPlayerSticky = false

let header = document.querySelector('[data-header]')
let isSticky = header.classList.contains('Header--sticky')


if (window.matchMedia("(min-width: 1100px)").matches) { 
  headerHeight = 156
  if(isSticky) {
    headerHeight = 111
  }
  mainOffset = 235
} else {
  headerHeight = 60
  if(isSticky) {
    headerHeight = 135
  }
  mainOffset = 135
}

function init () {

  if(!isPodcastListPage) return
  // As we are looking for a Vue element that can not be present (not mounted) in dom when the script is called 
  // We observe the dom for mutation until we find it 
  // just to be sure all other scripts are loaded and prevent false value on player.offsetHeight, we hade a ugly setTimeout ;)
  observeDom();
  
}

function observeDom() {
  let mutationObserver = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {

      let mutatedElement = mutation.target
      
      if(mutatedElement && mutatedElement.classList) {

        let audioPlayerElement = mutatedElement.classList.contains('SPFPlayer-playlist')

        if(audioPlayerElement) {
          player = document.querySelector(PLAYER_SELECTOR)

          if(player) {
            initPlayer() // start the script
            mutationObserver.disconnect(); // stop listening
          }
        }
      }
    });
  });
  
  // Starts listening for changes in the root HTML element of the page.
  mutationObserver.observe(document.documentElement, {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
    attributeOldValue: true,
    characterDataOldValue: true
  });

}


function initPlayer() {

  let offsetTop = getPlayerTopPosition()
  scroll = window.scrollY

  setTimeout(() => {
    stickyHandler(scroll, offsetTop)
  }, 1000);

  throttle( window.addEventListener('scroll', (e) => {
    scroll = window.scrollY

    stickyHandler(scroll, offsetTop)
  }) ,0)
  
}

function getPlayerTopPosition() {
  const header = document.querySelector('[data-header]')
  const isSticky = header.classList.contains('Header--sticky')
  
  let playerOffset = getOffset(player);
  let playerOffsetTop = playerOffset.top;

  if(isSticky) {
    playerOffsetTop = playerOffset.top + mainOffset;
  }
  
  return playerOffsetTop
}

function getOffset(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function stickyHandler(scroll, offsetTop) {
  const offset = 30
  const spacing = headerHeight + offset
  const title = document.querySelector('.SPFPlayer-infos')  

  let marginBottom 
  let marginBottomBase

  if (window.matchMedia("(min-width: 1100px)").matches) { 
    marginBottom = 104
    marginBottomBase = 30
  } else {
    marginBottom = 104
    marginBottomBase = 20
  }
  
  if(scroll >= offsetTop - spacing) {
    
    if(!isPlayerSticky){
      player.setAttribute('data-player-sticky', "true")
      player.style.top = `${spacing}px`
      title.style.marginBottom = `${marginBottom}px`
      isPlayerSticky = true
    }else {
      player.setAttribute('data-player-sticky', "true")
      if (window.matchMedia("(min-width: 1100px)").matches) { 
        player.style.top = `${headerHeight}px`
      }else {
        player.style.top = `80px`
      }
      
      title.style.marginBottom = `${marginBottom}px`
      isPlayerSticky = true
    }

  }else{

    if(isPlayerSticky){
      player.setAttribute('data-player-sticky', "false")
      player.style.top = 0
      title.style.marginBottom = `${marginBottomBase}px`
      isPlayerSticky = false
    }
    
  }
}

function throttle (callback, limit) {
  var tick = false;
  return function () {
    if (!tick) {
      callback.call();
      tick = true;
      setTimeout(function () {
        tick = false;
      }, limit);
    }
  }
}

export default { init }