import { axiosClient } from '../../js/axiosClient'
import { formSubmitHandler } from './MapForms'



// Selectors
const ADDRESS_INPUT_CONTAINER_SELECTOR = '[data-form-map-adress-container]'
const ADDRESS_INPUT_SELECTOR = '[data-form-map-adress-input]'
const RESULT_CONTAINER_SELECTOR = '[data-form-adress-autocomplete]'
const SUBMIT_BUTTON_SELECTOR = '[data-form-adress-button]'
const STATUS_SELECTOR = '[data-form-status]'

// Consts
const addressInputContainer = document.querySelector(ADDRESS_INPUT_CONTAINER_SELECTOR)
const addressInput = document.querySelector(ADDRESS_INPUT_SELECTOR)
const resultContainer = document.querySelector(RESULT_CONTAINER_SELECTOR)
const statusContainer = document.querySelector(STATUS_SELECTOR)
const submitButton = document.querySelector(SUBMIT_BUTTON_SELECTOR)
const loaderIcon = '<svg v-if="isLoading" class="InputAddress-loading" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>'

let limit = 5
let showResponse = false
let hasResponse = false
let hasChanged = false
let addressesArray = []
let count = 0
let refCount = 0
let debounceCount = 0
let debounce = null
let currentEvent
let isLoading = false

let searchSVG

const anchor = document.querySelector('#MapAnchor')


export function MapAddressAutoCompleteInit() {
  
  if (!addressInput) return 

  searchSVG = submitButton.innerHTML
  initAutocomplete()
  
}

function initAutocomplete() {
  addAdressInputListener()
  addOnSubmitListener()
  addOnBlurListener()
  addAxiosInterceptor()
}

// Add a keyUp listener on the search input Field
function addAdressInputListener() {
  addressInput.addEventListener('keyup', (event) => {
    if (event.key !== 'Enter' && event.key !== 'Tab') {
      onDebounce(event)
    }
  })
}

// Launch the Autocomplete Function 
function onDebounce(event) { // debounce used to prevent to many ajax calls
  currentEvent = event
  hasChanged = true
  count ++; // Update the count by 1

  clearTimeout(debounce); // Clear any existing debounce event

  debounce = setTimeout(async () => { // Update and log the counts after .5 seconds

    debounceCount++; // Update the debounceCount
    autocomplete(currentEvent)

  }, 500);
}

async function autocomplete(event) {

  const input = event.target
  const inputValue = input.value
  statusContainer.innerHTML = '' // reset the status div

  if(inputValue.length > 2) {
    
    let url = createQuery(inputValue) 
    
    // Axios Call - Get the adresses datas from Gouvernement API
    await axiosClient.get(url)
    .then((response) => {
      
      formatResponse(response)

      showResponse = true // if true show the list of results under the input search

      resultsHandler()
      createDomElements()

      hasResponse = addressesArray.length ? true : false

      if(hasResponse) {
        statusContainer.innerText = `${addressesArray.length} adresse(s) disponible(s)`
      } else {
        statusContainer.innerText = `Aucune proposition`
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }else {
    showResponse = false // if false hide the list of results under the input search
    resultsHandler()
  }
}

// Create the query for the ajax call with base rootUrl and params 
function createQuery(value) {
  let rootURL = "https://api-adresse.data.gouv.fr/search/?q="  
  return rootURL + value + '&limit=' + limit 
}

// Preparing the data. Get the adresses labels
// Set an array that is used to show results in the DOM 
function formatResponse(response) { // reset the values stored and create the array used to display the different locations
  let rawAddresses = response.data.features
  addressesArray = [] // reset values

  rawAddresses.forEach(address => {
    addressesArray.push(address.properties.label)
  });
}

// Show or hide the results container in the DOM
function resultsHandler() {
  if (showResponse && addressesArray.length) {
    resultContainer.setAttribute('aria-hidden', 'false')
    addressInputContainer.setAttribute('aria-expanded', 'true')
    focusResults()
  }else{
    resultContainer.setAttribute('aria-hidden', 'true')
    addressInputContainer.setAttribute('aria-expanded', 'false')
  }
}

function focusResults() {
  const results = document.querySelectorAll('[data-map-select-result]')
  if(!results.length) return 
  results[0].focus()
  console.log(results)
}

function createDomElements() {
  resultContainer.innerHTML = '' // reset the results on each search("keyup")

  addressesArray.forEach(result => {
    const button = document.createElement("button")
    const buttonContent = document.createTextNode(result);
    
    button.type = 'button'
    button.setAttribute('data-map-select-result', result)
    button.appendChild(buttonContent);
    
    button.addEventListener('mousedown', (e) => {
      e.stopImmediatePropagation(); // Mandatory as there is a click listener on results and blur listener on input 
      showResponse = false 
      resultsHandler()
      let buttonAttr = button.getAttribute('data-map-select-result')
      addressInput.value = buttonAttr
      formSubmitHandler(e)
    })
    
    button.addEventListener('keydown', (e) => {

      if(e.code !== 'Enter') return
      e.stopImmediatePropagation(); // Mandatory as there is a click listener on results and blur listener on input 
      showResponse = false 
      resultsHandler()
      let buttonAttr = button.getAttribute('data-map-select-result')
      addressInput.value = buttonAttr
      formSubmitHandler(e)
    })

    resultContainer.appendChild(button);
  });
}

// Close the results if enter key is pressed
function addOnSubmitListener() {
  addressInput.addEventListener("keyup", function(event) {
    if (event.key === 'Enter') {
      if(!isLoading)
      showResponse = false
      resultsHandler()
    }
  });
}

// Close the results if input is blurred
function addOnBlurListener() {
  
  // addressInput.addEventListener('blur', (e) => {
  //   e.stopImmediatePropagation()
  //   showResponse = false
  //   resultsHandler()
  // })
}

// Show the loader on ajax call
function addAxiosInterceptor() {
  axiosClient.interceptors.request.use((config) => {
    showLoader(true);
    return config;
  }, (error) => {
    showLoader(false);
    return Promise.reject(error);
  });
  
  axiosClient.interceptors.response.use((response) => {
    showLoader(false);
    return response;
  }, (error) => {
    showLoader(false);
    return Promise.reject(error);
  });
}

// Function used with the code in created to display the loader when axios is quering
function showLoader(isLoading) {
  if (isLoading) {
    refCount++;
    isLoading = true;
    submitButton.innerHTML = loaderIcon
  } else if (refCount > 0) {
    submitButton.innerHTML = searchSVG
    refCount--;
    isLoading = (refCount > 0);
  }
}