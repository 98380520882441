const ACCORDEON_SELECTOR = '[data-accordeon]'
const ACCORDEON_BUTTON_SELECTOR = '[data-accordeon-trigger]'
const ACCORDEON_ITEM_SELECTOR = '[data-accordeon-item]'
const ACCORDEON_CONTENT_SELECTOR = '[data-accordeon-content]'

const accordeons = document.querySelectorAll(ACCORDEON_SELECTOR)

function init() {

  if(!accordeons.length) return
  
  initAccordeons()
  
} 

function initAccordeons() {
  accordeons.forEach(accordeon => {
    
    const accordeonItems = accordeon.querySelectorAll(ACCORDEON_ITEM_SELECTOR)
    
    accordeonItems.forEach(item => {
      const itemButton = item.querySelector(ACCORDEON_BUTTON_SELECTOR)
      let isOpen = (item.getAttribute('data-isOpen') === 'true')

      initContentHeight(item)
      setContentHeight(item, isOpen)
      
      itemButton.addEventListener('click', (e) => {
        isOpen = (item.getAttribute('data-isOpen') === 'true')
        toggleAccordeon(isOpen, item, itemButton)

      })
    });

  });
}

function initContentHeight(item) {

  const content = item.querySelector(ACCORDEON_CONTENT_SELECTOR)
  const contentHeight = content.offsetHeight 
  
  content.setAttribute('data-height', contentHeight)
}

function setContentHeight(item, isOpen) {

  const content = item.querySelector(ACCORDEON_CONTENT_SELECTOR)
  const contentHeight = parseInt(content.getAttribute('data-height'))
  
  if(isOpen) {
    
    content.style.maxHeight =  `${contentHeight}px`
    
  } else {
    
    content.style.maxHeight =  0

  }

}


function toggleAccordeon(isOpen, item, button) {
  
  if(isOpen) {  

    closeAccordeon(item, button)
    
  } else {

    closeAllAccordeon()

    setTimeout(() => {
      openAccordeon(item, button)
    }, 100);

  }
}

function closeAccordeon(item, button) {
  
  item.setAttribute('data-isOpen', 'false')
  button.setAttribute('aria-expanded', 'false')

  const content = item.querySelector(ACCORDEON_CONTENT_SELECTOR)
  content.style.maxHeight =  0
  
}

function closeAllAccordeon() {
  accordeons.forEach(accordeon => {
    const accordeonItems = accordeon.querySelectorAll(ACCORDEON_ITEM_SELECTOR)

    accordeonItems.forEach(item => {
      const itemButton = item.querySelector(ACCORDEON_BUTTON_SELECTOR)
      closeAccordeon(item, itemButton)
    });
  });
}

function openAccordeon(item, button) {
  
  item.setAttribute('data-isOpen', 'true')
  button.setAttribute('aria-expanded', 'true')

  const content = item.querySelector(ACCORDEON_CONTENT_SELECTOR)
  const contentHeight = parseInt(content.getAttribute('data-height'))
  content.style.maxHeight =  `${contentHeight}px`

}
 
export default { init }