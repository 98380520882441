var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transcriptionUrl
    ? _c(
        "a",
        {
          staticClass: "SPFPlayer-transcription",
          attrs: {
            href: _vm.transcriptionUrl,
            target: "_blank",
            rel: "nofollow"
          }
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 512 512"
              }
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d:
                    "M352.214 0H60.992v512h390.015V98.793L352.214 0zm47.575 90.002l-38.779-.005-.005-38.779 38.784 38.784zM90.992 482V30h240.011l.011 89.993 89.993.011V482H90.992z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d:
                    "M126 151.98h170v30H126zM126 211.98h259.01v30H126zM126 271.99h259.01v30H126zM126 331.99h259.01v30H126zM292.26 391.99H385v30h-92.74z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.transcriptionLabel))])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }