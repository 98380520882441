import Swiper, { Navigation, Pagination, EffectFade, A11y, Keyboard } from '../../../node_modules/swiper/swiper-bundle'
import FadeHover from '../../js/fadeHover.js'

const HERO_CARD_SLIDER_SELECTOR = '[data-hero-card-slider]'

const heroCardSlider = document.querySelector(HERO_CARD_SLIDER_SELECTOR)
const heroCardSliders = document.querySelectorAll(HERO_CARD_SLIDER_SELECTOR)

function init() {
 
  if (!heroCardSlider) return 

  if (window.matchMedia("(max-width: 1100px)").matches) {
    initSlider()
  }
  
  const options = {
    selector : '[data-hover-fade]'
  }

  if (heroCardSliders.length){
    const fadeHoverInstance = new FadeHover(heroCardSliders, options)
    fadeHoverInstance.init()
  }

} 

function initSlider() {

  
  const sliderOptions = {
    slidesPerView: "auto",
    spaceBetween: 30,
    simulateTouch: true,
    watchSlidesProgress: true,
    watchOverflow: true,
    pagination: {
      el: ".HeroCards-bullets",
      bulletElement:'button',
      clickable: true
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false
    },
    a11y: {
      enabled: false,
      prevSlideMessage: "Slide précédent",
      nextSlideMessage: "Slide suivant"
    },
    breakpoints: {
      // when window width is >= 640px
      1100: {
        initialSlide : 0,
        centeredSlides: 0
      }
    },
    on: {
      init: function () {
        this.slides.forEach(slide => {
          const slideLinks = slide.querySelectorAll('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            if(slideLinks.length){
              slideLinks.forEach(slideLink => {
                slideLink.setAttribute('tabindex', '-1')
              });
            }
          } else {
            slideLinks.forEach(slideLink => {
              slideLink.setAttribute('tabindex', 'false')
            });
          }
        })
      },
    }
  }

  const heroSlider = new Swiper(heroCardSlider, sliderOptions);

}
 
export default { init }