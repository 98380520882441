var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Form-input-icon" }, [
    !_vm.isLoading
      ? _c(
          "svg",
          {
            staticClass: "icon icon--valid",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "15",
              height: "15",
              viewBox: "0 0 15 14"
            }
          },
          [
            _c("path", {
              attrs: {
                d: "m3.85 11.270089 4.440104 5.481585L18.751563 4.769531",
                transform: "scale(.68182 .63636)",
                fill: "none",
                "stroke-width": "3",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                stroke: "#000"
              }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "svg",
          {
            staticClass: "icon icon--error",
            attrs: {
              width: "15",
              height: "15",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 34 34"
            }
          },
          [
            _c("path", {
              attrs: {
                fill: "currentColor",
                d:
                  "M34 4l-4-4-13 13L4 0 0 4l13 13L0 30l4 4 13-13 13 13 4-4-13-13"
              }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoading
      ? _c(
          "svg",
          {
            staticClass: "InputAddress-loading",
            attrs: { viewBox: "0 0 50 50" }
          },
          [
            _c("circle", {
              staticClass: "path",
              attrs: {
                cx: "25",
                cy: "25",
                r: "20",
                fill: "none",
                "stroke-width": "5"
              }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }