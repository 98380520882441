import { render, staticRenderFns } from "./InputRadio.vue?vue&type=template&id=0db6398f&"
import script from "./InputRadio.vue?vue&type=script&lang=js&"
export * from "./InputRadio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/production/spf-sexosafe-ezplatform/src/AppBundle/Resources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0db6398f')) {
      api.createRecord('0db6398f', component.options)
    } else {
      api.reload('0db6398f', component.options)
    }
    module.hot.accept("./InputRadio.vue?vue&type=template&id=0db6398f&", function () {
      api.rerender('0db6398f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TestForm/components/InputRadio/InputRadio.vue"
export default component.exports