var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TPEForm" },
    [
      _vm.componentId > 1 || _vm.componentType === "Result"
        ? _c("PreviousButton", {
            on: { "PreviousButton::previous-action": _vm.previousAction }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _c(_vm.componentType, {
            tag: "component",
            attrs: {
              index: _vm.componentId,
              questions: _vm.questions,
              results: _vm.results,
              introduction: _vm.introduction,
              componentType: _vm.componentType,
              componentId: _vm.componentId
            },
            on: {
              "Introduction::start-question": _vm.startQuestion,
              "Result::reset-form": _vm.resetForm,
              "Question::set-data": _vm.setData,
              "Question::set-action": _vm.setAction
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }