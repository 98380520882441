<template>
  <div class="Introduction container container--small container--isStatic ">
    <div class="container-container">
      <div class="container-inner">
        <div class="container-content">

          <Sticker> 
            <span>{{ introduction.sticker.textThin }}</span>
            <span class="bold">{{ introduction.sticker.textBold }}</span>
          </Sticker>

          <h3 class="Introduction-title TPEForm-title ">{{ introduction.title}}</h3>
          
          <p class="Introduction-text">{{ introduction.text}}</p>

          <button
            class="Button Button--white"
            @click="startQuestion" 
          >
            <span class="Button-label">{{ introduction.buttonLabel}}</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sticker from "../Sticker/Sticker.vue"

export default {
  name: "Introduction",
  components: {
    Sticker
  },
  props: {
    introduction: {
      required: true,
      type: Object,
    },
  },
  methods: {
    startQuestion() {
      this.$emit("Introduction::start-question")
    }
  }
}
</script>