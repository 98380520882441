<template>
  <button class="PreviousButton PreviousButton--popin" type="button" data-form-button="previous" @click="previousAction">
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10">
      <path d="M6.804688 0 1.792969 5.011719l5.011719 5.007812 1.421874-1.421875-3.589843-3.585937 3.589843-3.589844Zm0 0"/>
    </svg>
    <span>Précédent</span>
  </button>
</template>

<script>
export default {
  name: 'PreviousButton',
  methods: {
    previousAction() {
      this.$emit("PreviousButton::previous-action")
    }
  }
}
</script>