import Swiper, { Navigation, Pagination, EffectFade, A11y, Keyboard } from '../../../node_modules/swiper/swiper-bundle.min.js'

const SLIDER_SELECTOR = '[data-hero-slider]'
const slider = document.querySelector(SLIDER_SELECTOR)

const PLAY_BUTTON_SELECTOR = '[data-slider-play]'
const PAUSE_BUTTON_SELECTOR = '[data-slider-pause]'

const playButton = document.querySelector(PLAY_BUTTON_SELECTOR)
const pauseButton = document.querySelector(PAUSE_BUTTON_SELECTOR)

let heroSlider 


function init() {
 
  if (!slider) return
  initSlider()
  
  if (!playButton || !pauseButton) return
  initSliderPlayer()

} 

function initSlider() {

  let speedSlide 
  
  if (window.matchMedia("(min-width: 1100px)").matches) {
    speedSlide = 1200
  }else {
    speedSlide = 600
  }
  
  const sliderOptions = {
    a11y: {
      enabled: false,
      prevSlideMessage: 'slide précédente',
      nextSlideMessage: 'slide suivante',
      firstSlideMessage: "Première slide",
      lastSlideMessage: "Dernière slide",
      paginationBulletMessage: 'Aller à la slide {{index}}'
    },
    speed: speedSlide,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    speed: 700,
    watchSlidesProgress: true,
    pagination: {
      el: '[data-hero-slider-pagination]',
      type: 'bullets',
      bulletElement:'button',
      clickable: true,
      renderBullet: function (index, className) {
        return `<button class="${className}"><span class="sr-only">Diapositive ${index + 1}</span></button>`
      }
    },
    on: {
      init: function () {

        const activeButton = this.el.querySelector('.swiper-pagination-bullet-active')
        if (activeButton) {
          activeButton.setAttribute('aria-current', 'true')
        }

        this.slides.forEach(slide => {
          const slideLink = slide.querySelector('a')

          if (!slide.classList.contains('swiper-slide-visible')) {
            slide.setAttribute('aria-hidden', 'true')
            if(slideLink){
              slideLink.setAttribute('tabindex', '-1')
            }
          } else {
            slide.setAttribute('aria-hidden', 'false')
          }
        })
      },
    }
  }
  
  heroSlider = new Swiper(slider, sliderOptions)

  heroSlider.on('transitionEnd', function () {
    const allButtons = this.el.querySelectorAll('.swiper-pagination-bullet')
    allButtons.forEach(button => {
      if (button.classList.contains('swiper-pagination-bullet-active')) {
        button.setAttribute('aria-current', 'true')
      }
      else {
        button.removeAttribute('aria-current')
      }
    })
    
    this.slides.forEach(slide => {
      const slideLink = slide.querySelector('a')
      
      if (!slide.classList.contains('swiper-slide-visible')) {
        slide.setAttribute('aria-hidden', 'true')
        if(slideLink){
          slideLink.setAttribute('tabindex', '-1')
        }
      } else {
        slide.setAttribute('aria-hidden', 'false')
        if(slideLink){
          slideLink.removeAttribute('tabindex')
        }
      }
    })

    this.slides[this.activeIndex].focus()
  })
  
}

function initSliderPlayer() {

  playButton.addEventListener('click', () => {
    heroSlider.autoplay.stop();
    playButton.setAttribute('aria-hidden', 'true')
    pauseButton.setAttribute('aria-hidden', 'false')
  })
  
  pauseButton.addEventListener('click', () => {
    heroSlider.autoplay.start();
    playButton.setAttribute('aria-hidden', 'false')
    pauseButton.setAttribute('aria-hidden', 'true')
  })

}
 
export default { init }