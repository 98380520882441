<template>
  <div class="TPEForm">
    <PreviousButton
      v-if="componentId > 1 || componentType === 'Result'"
      @PreviousButton::previous-action="previousAction"
    />

    <transition name="fade" mode="out-in">
      <component
        :is="componentType"
        :index="componentId"
        :questions="questions"
        :results="results"
        :introduction="introduction"
        :componentType="componentType"
        :componentId="componentId"
        @Introduction::start-question="startQuestion"
        @Result::reset-form="resetForm"
        @Question::set-data="setData"
        @Question::set-action="setAction"
      ></component>
        <!-- @Hook::mounted="tpeResult" -->
    </transition>
        <!-- v-if="componentId !== null && componentType !== 'Introduction'" -->
  </div>
</template>

<script>
import Introduction from "../VueFormComponents/Introduction/Introduction.vue"
import Question from "./components/Question/Question.vue"
import Result from "./components/Result/Result.vue"
import PreviousButton from "./components/PreviousButton/PreviousButton.vue"

export default {
  name: "Form",
  components: {
    Question,
    Result,
    PreviousButton,
    Introduction
  },
  props: {
    questions: {
      required: true,
      type: Array,
    },
    results: {
      required: true,
      type: Array,
    },
    introduction: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      componentId: null,
      componentType: "Introduction",
      actionStorage: []
    }
  },
  computed: {},
  methods: {
    setData(data) {

    },
    setAction(action) {

      // only update the actionStorage array and localStorage if componentType is a question
      this.actionStorage.push(JSON.parse(JSON.stringify(action)))
      localStorage.setItem('TPE_action', JSON.stringify(this.actionStorage))
      this.componentType = action.componentType
      this.componentId = action.id

    },
    previousAction() {

      // remove last entry of actionStorage and keep it in a const in case we need it
      const lastActionStorageEntry = this.actionStorage.splice(-1)
      localStorage.setItem('TPE_action', JSON.stringify(this.actionStorage))

      const lastAction = lastActionStorageEntry[0]

      this.componentType = "Question"
      this.componentId = parseInt(lastAction.lastActionId)

    },
    setLocalStorageData(key, value) {
      localStorage.setItem(key, value)
    },
    startQuestion() {
      this.componentType = "Question"
      this.componentId = 0
    },
    resetForm() {
      this.componentType = "Introduction"
      this.componentId = null
      this.actionStorage= []
      localStorage.removeItem('TPE_action');
      localStorage.removeItem('TPE_data');
    },
    resetFormOnClose() {
      const closeButtons = document.querySelectorAll('[data-form-reset]') 

      if(!closeButtons.length) return

      closeButtons.forEach(button => {
        button.addEventListener('click', () => {
          this.resetForm()
        })
      });
    },

  },
  beforeMount() {
    this.resetForm() // Here to reset values on load 
    this.resetFormOnClose() // Here to add the reset event to the popin close 
  },
}
</script>