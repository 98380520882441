var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Controls" }, [
    _c(
      "button",
      {
        staticClass: "Controls-button",
        attrs: { disabled: _vm.isFirstMedia },
        on: { click: _vm.previousMedia }
      },
      [_vm._v(_vm._s(_vm.previousMediaLabel))]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "Controls-button",
        attrs: { disabled: _vm.isLastMedia },
        on: { click: _vm.nextMedia }
      },
      [_vm._v(_vm._s(_vm.nextMediaLabel))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }