import { nanoid } from 'nanoid'




class Autocomplete {

  constructor(container) {
    this.searchContainer = container
    this.searchForm = this.searchContainer.querySelector('[data-search-form]')
    this.searchInput = this.searchContainer.querySelector('[data-search-input]')
    this.searchReset = this.searchContainer.querySelector('[data-search-reset]')
    this.searchStatus = this.searchContainer.querySelector('[data-search-status]')
    this.searchAutocomplete = this.searchContainer.querySelector('[data-search-autocomplete]')
    this.init()
  }

  init() {
    this.accessibility()
    this.keyboardNav()
    this.typeSearch()
    this.formReset()
    this.formFocusBlur()
    this.inputStates(this.searchForm, this.searchInput)
  }

  accessibility() {
    const searchInput = this.searchInput
    const searchAutocomplete = this.searchAutocomplete

    if (!searchAutocomplete) return

    const searchId = nanoid(6)

    searchInput.setAttribute('role', 'combobox')
    searchInput.setAttribute('aria-expanded', 'false')
    searchInput.setAttribute('aria-autocomplete', 'list')
    searchInput.setAttribute('aria-controls', `Search-${searchId}`)

    searchAutocomplete.setAttribute('role', 'listbox')
    searchAutocomplete.setAttribute('id', `Search-${searchId}`)
  }

  keyboardNav() {
    const searchInput = this.searchInput
    const searchAutocomplete = this.searchAutocomplete

    if (!searchAutocomplete) return

    let suggestions = []
    let currentFocus

    searchInput.addEventListener('keydown', (e) => {
      suggestions = searchAutocomplete.querySelectorAll('[data-search-autocompleteitem]')

      const firstLink = suggestions[0]
      const lastLink = suggestions[length - 1]

      if (suggestions.length) {
        switch (e.code) {
          case 'ArrowDown':

            e.preventDefault()
            firstLink.focus()
            firstLink.classList.add('active')
            firstLink.setAttribute('aria-selected', 'true')
            currentFocus = 0
            break;

          case 'ArrowUp':

            e.preventDefault()
            lastLink.focus()
            lastLink.classList.add('active')
            lastLink.setAttribute('aria-selected', 'true')
            currentFocus = suggestions.length - 1
            break;

          default:
            break;
        }
      }
    })

    searchAutocomplete.addEventListener('keydown', (e) => {
      if (suggestions.length) {
        if (document.activeElement.classList.contains('Search-autocompleteItem')) {

          switch (e.code) {
            case 'ArrowDown':
              e.preventDefault()

              if (currentFocus < (suggestions.length - 1)) {
                currentFocus++
              } else {
                currentFocus = 0
              }

              suggestions.forEach(suggestion => {
                suggestion.classList.remove('active')
                suggestion.removeAttribute('aria-selected')
              })
              suggestions[currentFocus].classList.add('active')
              suggestions[currentFocus].setAttribute('aria-selected', 'true')
              suggestions[currentFocus].focus()

              break;
            case 'ArrowUp':
              e.preventDefault()
              
              if (currentFocus > 0) {
                currentFocus--
              } else {
                currentFocus = suggestions.length - 1
              }

              suggestions.forEach(suggestion => {
                suggestion.classList.remove('active')
                suggestion.removeAttribute('aria-selected')
              })

              suggestions[currentFocus].classList.add('active')
              suggestions[currentFocus].setAttribute('aria-selected', 'true')
              suggestions[currentFocus].focus()

              break;
            case 'Escape':
              e.preventDefault()

              suggestions.forEach(suggestion => {
                suggestion.classList.remove('active')
                suggestion.removeAttribute('aria-selected')
              })

              searchInput.focus()
              break;
            case 'Tab':
              e.preventDefault()
              break;
            default:
              break;
          }
        }
      }
    })
  }

  renderAutocomplete(data, keywords) {
    const searchForm = this.searchForm
    const searchInput = this.searchInput
    const searchAutocomplete = this.searchAutocomplete

    if (!searchAutocomplete) return

    searchAutocomplete.innerHTML = ''

    if (data.length === 0) {
      searchForm.classList.remove('Search-form--autocomplete-open')
      searchInput.setAttribute('aria-expanded', 'false')
      this.searchStatus.innerHTML = "Aucune proposition"
    } else {
      searchForm.classList.add('Search-form--autocomplete-open')
      searchInput.setAttribute('aria-expanded', 'true')
      let message
      if(data.length === 1)
      message = "proposition disponible"
      if(data.length > 1)
      message = "propositions disponibles"
      this.searchStatus.innerHTML = `${data.length} ${message}`

      data.forEach((result, index) => {
        const listItem = document.createElement('li')
        listItem.setAttribute('data-search-autocompleteItem', '')
        listItem.classList.add('Search-autocompleteItem')
        listItem.setAttribute('tabindex', '0')
        listItem.setAttribute('role', 'option')
        listItem.setAttribute('aria-posinset', index + 1)
        listItem.setAttribute('aria-setsize', data.length)
        listItem.innerHTML = this.highlightKeywords(keywords, result.text)
        listItem.addEventListener('click', () => {
          window.location.replace(result.href)
        })
        searchAutocomplete.appendChild(listItem)
      })
    }
  }

  resultsFetch() {
    const searchForm = this.searchForm
    const searchInput = this.searchInput
    const searchStatus = this.searchStatus

    searchStatus.innerHTML = ""

    if (searchInput.value.length < 3) {
      searchForm.classList.remove('Search-form--autocomplete-open')
      searchInput.setAttribute('aria-expanded', 'false')

      return
    }

    fetch(`/suggestions/${encodeURI(searchInput.value)}`)
      .then(response => response.json())
      .then(data => this.renderAutocomplete(data, searchInput.value))
  }

  inputStates(form, input) {
    if (input.value.length > 0) {
      if (!form.classList.contains('Search-form--is-filled')) {
        form.classList.add('Search-form--is-filled')
      }
    } else {
      if (form.classList.contains('Search-form--is-filled')) {
        form.classList.remove('Search-form--is-filled')
      }
    }
  }

  typeSearch() {
    const searchInput = this.searchInput
    let typingTimer

    searchInput.addEventListener('input', () => {
      this.inputStates(this.searchForm, searchInput)

      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => {
        this.resultsFetch()
      }, 250)
    })
  }

  highlightKeywords(keywords, title) {
    const words = keywords.split(" ")
    const span = "<em></em>"

    const e = ['È', 'É', 'Ê', 'Ë', 'è', 'é', 'ê', 'ë'],
      a = ['à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ']

    const substitutions = {
      e, E: e, a, A: a
    }

    words.forEach(word => {
      if (span.indexOf(word) == -1) {

        const pattern = Array.from(word).map(c => substitutions[c] ? `[${c}${substitutions[c].join("")}]` : c).join("")
        let re = new RegExp(pattern, "gi")
        title = title.replace(re, "<em>$&</em>")
      }
    })

    return title
  }

  formReset() {
    const searchForm = this.searchForm
    const searchReset = this.searchReset
    const searchInput = this.searchInput
    const searchAutocomplete = this.searchAutocomplete
    const searchStatus = this.searchStatus

    searchReset.addEventListener('click', () => {
      searchForm.classList.remove('Search-form--autocomplete-open')
      searchInput.setAttribute('aria-expanded', 'false')
      searchStatus.innerHTML = ""
      
      if (searchAutocomplete) {
        searchAutocomplete.innerHTML = ''
      }

      searchInput.value = ''
      searchInput.focus()
      searchForm.classList.remove('Search-form--is-filled')
    })
  }

  formFocusBlur() {
    const searchContainer = this.searchContainer
    const searchForm = this.searchForm
    const searchInput = this.searchInput
    const searchAutocomplete = this.searchAutocomplete

    document.addEventListener('click', (e) => {
      const target = e.target || e.srcElement || e.currentTarget

      if (!searchContainer.contains(target)) {
        searchForm.classList.remove('Search-form--autocomplete-open')
        searchInput.setAttribute('aria-expanded', 'false')
      }
    })

    searchInput.addEventListener('keydown', (e) => {
      if (e.code === "Escape") {
        searchForm.classList.remove('Search-form--autocomplete-open')
        searchInput.setAttribute('aria-expanded', 'false')
      }
    })

    if (searchAutocomplete) {
      searchInput.addEventListener('focus', () => {
        if (searchAutocomplete.innerHTML !== '' && !searchForm.classList.contains('Search-form--autocomplete-open') && searchInput.value.length >= 3) {
          searchForm.classList.add('Search-form--autocomplete-open')
          searchInput.setAttribute('aria-expanded', 'true')
        }
      })
    }
  }
}

function init() {
  const searchBlocks = document.querySelectorAll('[data-search-block]')

  if (!searchBlocks.length) return

  searchBlocks.forEach(searchBlock => {
    const autocompleteInit = new Autocomplete(searchBlock)
  })
}


export default { init }
