<template>
  <div class="InputCheckbox">
    <div class="InputCheckbox-container">
      <div class="Form-row">
       <div
          v-for="(input, index) in answer.list"
          :key="index"
       >
        <input 
          class="InputCheckbox-input Form-input"
          type="checkbox"
          :name="`InputCheckbox-${input.id}`"
          :id="`InputCheckbox-${input.id}`"
          :data-label="input.label"
          :data-value="input.value"
          :data-identifier="input.identifier"
          @change="setData"
        />
        <label class="InputCheckbox-label Form-label" :for="`InputCheckbox-${input.id}`">{{ input.label }}</label>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"InputCheckbox",
  props: {
    answer: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      userData: [],
      checkedInput: []
    }
  },
  methods: {
    setData(event) {

      let element = event.target
      const elementId = element.id
      const elementLabel = element.getAttribute('data-label')
      const elementIdentifier = element.getAttribute('data-identifier')
      const elementValue = element.getAttribute('data-value')
      const isChecked = this.isChecked(event)
      const elementObject = JSON.parse(JSON.stringify({"questionId": elementId, "questionLabel": elementLabel, "questionValue": elementValue, 'questionIdentifier': elementIdentifier,multiple: true }))

      if(isChecked){
        this.addData(elementObject)
      }else {
        this.removeData(elementObject)
      }

      this.verifyData()
    },
    isChecked(event) {
      return event.target.checked
    },
    addData(data) {
      this.userData.push(data)
      this.checkedInput.push(data.questionId)
    },
    removeData(data) {
      const index = this.checkedInput.indexOf(data.questionId)
      this.checkedInput.splice(index, 1)
      this.userData.splice(index, 1)
    },
    verifyData() {
      if (this.userData.length > 0) {
        this.$emit('InputCheckbox::set-data', this.userData)
      }else {
        this.$emit('InputCheckbox::set-data', [])
      } 
    }
  },
}
</script>