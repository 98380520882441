var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "InputSelect" }, [
    _c("div", { staticClass: "InputSelect-container" }, [
      _c("div", { staticClass: "Form-row InputSelect-row" }, [
        _c(
          "select",
          {
            staticClass: "InputSelect-input Form-input",
            attrs: { id: "selectCountry", "data-input-select": "" },
            on: {
              change: function($event) {
                return _vm.moveLabel()
              }
            }
          },
          [
            _c("optgroup", { attrs: { label: "Pays favoris" } }, [
              _c("option", { attrs: { "data-placeholder": "true" } }),
              _vm._v(" "),
              _c(
                "option",
                {
                  domProps: { value: _vm.favoriteCountry.value },
                  on: {
                    click: function($event) {
                      return _vm.nextAction(_vm.favoriteCountry.value)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.favoriteCountry.country))]
              )
            ]),
            _vm._v(" "),
            _c(
              "optgroup",
              { attrs: { label: "Tous les pays" } },
              _vm._l(_vm.countryList, function(country, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: country.value } },
                  [_vm._v(_vm._s(country.country))]
                )
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "InputSelect-label Form-label",
            attrs: { for: "selectCountry" }
          },
          [_vm._v(_vm._s(_vm.answer.label))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }