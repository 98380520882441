import { store } from './Map'

const MAP_FORM_SELECT_SELECTOR = '[data-map-form-select]'
const MAP_FORM_SELECT_OPTION_CONTAINER_SELECTOR = '[data-form-select-options]'
const MAP_FORM_SELECT_HIDDEN_INPUT = '[data-select-hidden-input]'
const MAP_FORM_SELECT_OPTIONS = '[data-map-select-option]'
const MAP_FORM_SELECT_OVERLAY = '[data-map-select-overlay]'

const mapFormSelect = document.querySelector(MAP_FORM_SELECT_SELECTOR)
const mapFormSelectHiddenInput = document.querySelector(MAP_FORM_SELECT_HIDDEN_INPUT)
const mapFormSelectOptionContainer = document.querySelector(MAP_FORM_SELECT_OPTION_CONTAINER_SELECTOR)
const mapFormSelectOptions = document.querySelectorAll(MAP_FORM_SELECT_OPTIONS)
const mapFormSelectOverlay = document.querySelector(MAP_FORM_SELECT_OVERLAY)

let selectLabel
let selectValue

export function MapFormSelectInit() {

  if (!mapFormSelect) return

  initSelect()
  initOverlay()
   
}

function initSelect() {
  
  mapFormSelect.addEventListener('click', (e) => {
    e.stopImmediatePropagation()
    selectHandler(mapFormSelect, mapFormSelectOptionContainer, mapFormSelectOverlay)
  })

  mapFormSelectOptions.forEach(option => {   

    option.addEventListener('click', (e) => {
      e.stopImmediatePropagation()
      
      selectLabel = option.getAttribute('data-label') 
      selectValue = option.getAttribute('data-value')

      
      hideOptions(mapFormSelect, mapFormSelectOptionContainer, mapFormSelectOverlay)
      fillInputValue(mapFormSelect, mapFormSelectHiddenInput, selectLabel, selectValue)
      
      const mapForm = document.querySelector('[data-map-form-search], [data-map-form-home]')
      const adressInputValue = mapForm.querySelector('[data-form-map-adress-input]').value
      const centerId = mapForm.querySelector('[data-map-form-select] [data-center-id]').getAttribute('data-center-id')
      
      store.input = {
        address: adressInputValue,
      }
      
      store.service = centerId
      
      mapFormSelectOptions.forEach(option => {   
        option.setAttribute('aria-hidden', 'false')
      });
      option.setAttribute('aria-hidden', 'true')
      // const anchor = document.querySelector('#MapAnchor')
      // anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})

    })

  });
}

function initOverlay() {
  mapFormSelectOverlay.addEventListener('click', (e) => {
    hideOptions(mapFormSelect, mapFormSelectOptionContainer, mapFormSelectOverlay)
  })
}
  
function selectHandler(select, options, overlay) {

  const isHidden = options.getAttribute('aria-hidden') === 'true'
  
  if(isHidden) {
    showOptions(select, options, overlay)
  }else {
    hideOptions(select, options, overlay)
  }

}

function showOptions(select, optionsContainer, overlay) {
  overlay.setAttribute('aria-hidden', 'false')
  select.setAttribute('data-map-form-select', 'open')
  select.setAttribute('aria-expanded', 'true')
  optionsContainer.setAttribute('aria-hidden', 'false')
}

function hideOptions(select, optionsContainer, overlay) {
  overlay.setAttribute('aria-hidden', 'true')
  select.setAttribute('data-map-form-select', 'closed')
  select.setAttribute('aria-expanded', 'false')
  optionsContainer.setAttribute('aria-hidden', 'true')
}

function fillInputValue(select, input, selectLabel, selectValue) {
  const textContainer = select.querySelector('span')
  textContainer.innerHTML = selectLabel
  textContainer.setAttribute('data-center-id', selectValue)
  input.value = selectValue
  input.dispatchEvent(new Event('input'));
}
