import Vue from 'vue'
import TestForm from './TestForm.vue'

Vue.config.devtools = true

let self = {}

self.init = function() {
  // Init player for each player on the current page
  const testForms = document.querySelectorAll('[data-form="test"]')
  testForms.forEach((TestFormContainer) => {

    // Create eventBus to centralize event transmission between component
    const eventBus = new Vue()

    const data = JSON.parse(TestFormContainer.getAttribute('data'))

    let propsForm = {
      eventBus: eventBus,
      questions: data.questions,
      result: data.result,
    }

    new Vue({
      render: h => h(TestForm, { props: propsForm })
    }).$mount(TestFormContainer)
  })
}

export default self