import { updateStructureList } from './MapList'
import { listParams, mapParams, store } from './Map'
import throttle from './../../js/throttle'

const MAP_RESULT_CONTAINER_SELECTOR = '[data-map-resultsContainer]'
const MAP_RESULT_CONTENT_SELECTOR = '[data-map-resultsContent]'
const MAP_CONTAINER_SELECTOR = '[data-results-mapContainer]'
const MAP_RESULTS_LIST_SELECTOR = '[data-map-resultsList]'
const LARGE_SCREEN = window.matchMedia("(max-width: 1099px)")

const mapResultsContainer = document.querySelector(MAP_RESULT_CONTAINER_SELECTOR)
const mapResultsContent = document.querySelector(MAP_RESULT_CONTENT_SELECTOR)
const mapContainer = document.querySelector(MAP_CONTAINER_SELECTOR)
const resultsList = document.querySelector(MAP_RESULTS_LIST_SELECTOR)
const largeBP = LARGE_SCREEN

//const mapContainer = document.querySelector('[data-results-map]') //DOM container of the MAP

let map // Will contain the map Object
let geocoder // create and init geocoder to interpret form address

// Init the geocoder of google map that allows to retrieve coordinates from address
export function initGeocoder() {
  if (typeof google === 'undefined') return
  geocoder = new google.maps.Geocoder()
}

// Update the google map 
export function geocodeAddress(address, callback) {

  geocoder.geocode({ 'address': address || 'Paris'}, function(results, status) {
    if (status === 'OK') {
      store.startLocation = results[0].geometry.location
      mapParams.mapCenter.lat = store.startLocation.lat()
      mapParams.mapCenter.lng = store.startLocation.lng()
      callback()
    } else {
      store.structures = []
      updateStructureList()
    }
  })
}


// Create and init google map
export function createMap() {

  if (!mapContainer || typeof google === 'undefined') return

  map = new google.maps.Map(mapContainer, {
    center: { lng: mapParams.mapCenter.lng, lat: mapParams.mapCenter.lat },
    zoom: mapParams.zoom.current
  })
}

// Set the center of the map
export function updateMapCenter() {
  map.setCenter(mapParams.mapCenter)
}

// Create infowindows and add them to the store
export function updateMapInfoWindows(offset) {
  clearMapInfoWindows()
  
  const structureList = store.structures.slice(0, offset + listParams.limitPerPage)

  structureList.forEach(structure => {
    createInfoWindow(structure)
  })
}

// Remove the overlay that explain how to use the map 
// And informations about structure (pins informations)
function clearMapInfoWindows() {
  store.infoWindows.forEach(infoWindow => infoWindow.setMap(null))
  store.infoWindows = []
}

// Create / Update the pin in the map
function createInfoWindow(structure) {
  store.infoWindows.push(new google.maps.InfoWindow({
    content: `<div class="MapInfoWindow">
      <span class="MapResult-nameInfo">${structure.name}</span>
      <address class="MapResult-address">
        <span class="MapResult-address1">${structure.address1}</span>
        <span class="MapResult-postcode">${structure.postcode.padStart(5, '0')}</span>
        <span class="MapResult-city">${structure.city}</span>
      </address>
    </div>`
  }))
}

// Open an infowindow on marker click
export function onMarkerClick() {
  store.markers.forEach((marker, index) => {
    marker.addListener('click', () => {
      store.markers.forEach((elem, index) => {
        store.infoWindows[index].close()
      });
      store.infoWindows[index].open(map, marker)

      const centerTitle = store.infoWindows[index].anchor.title
      const results = document.querySelectorAll('.MapResults-result')
      
      results.forEach(result => {
        result.classList.remove('MapResults-result--is-selected')
        const title = result.querySelector('.MapResult-name').innerHTML

        if(title === centerTitle) {
          result.classList.add('MapResults-result--is-selected')

          const yOffset = -100; 
          const y = result.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({top: y, behavior: 'smooth'});
        }
      });

    })
  })
}

// Create markers and add them to the store
export function updateMapMarkers(offset) {
  clearMapMarkers()

  const structureList = store.structures.slice(0, offset + listParams.limitPerPage)
  // const icon = createPin()
  // const url = window.location.hostname

  const icon = `/bundles/app/images/pinMap.svg`

  structureList.forEach((structure, index) => {
    const marker = createMarker(structure, icon, index)
    store.markers.push(new google.maps.Marker(marker))
  })
}

// Create the the Pin (marker) 
function createPin () {
  const pinPath = 'M22 61s22-26.85 22-39C44 9.85 34.15 0 22 0S0 9.85 0 22s22 39 22 39Zm-.5-31a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z'
  const pinColor = '#5E63FC'
  const pinOpacity = 1
  const pinStrokeColor = 'transparent'
  const pinStrokeOpacity = 0
  const pinAnchor = new google.maps.Point(0,0)
  const pinScale = 1.3

  return {
    path: pinPath,
    fillColor: pinColor,
    fillOpacity: pinOpacity,
    strokeColor: pinStrokeColor,
    strokeOpacity: pinStrokeOpacity,
    anchor: pinAnchor,
    scale: pinScale
  }
}

// Create the tooltip with text in it above the pin
function createMarker(structure, icon, index) {

  const markerText = ' '
  const markerColor = '#fff'
  const markerFont = '"Boing", sans-serif'
  const markerFontSize = '18px'
  const markerFontWeight = '700'
  const markerIndex = 53 - index

  const marker = {
    position: { lat: structure.latitude, lng: structure.longitude },
    map: map,
    title: structure.name,
    label: {
      text: markerText,
      color: markerColor,
      fontFamily: markerFont,
      fontSize: markerFontSize,
      fontWeight: markerFontWeight
    },
    icon: icon,
    zIndex: markerIndex
  }

  return marker
}

// Clear all map markers
function clearMapMarkers() {
  store.markers.forEach(marker => marker.setMap(null))
  store.markers = []
}

// update map zoom relative to farthest structure
export function updateMapZoom() {
  
  if (!store.structures.length) return

  const fartherStructure = store.farthestStructure.distance // last structure of the list (the one with the higher distance value )
  const calculatedZoom = Math.round(getBaseLog(2, 40000 / (fartherStructure / 2))) 
  const currentZoom = valueLimit((calculatedZoom - 1), mapParams.zoom.min, mapParams.zoom.max)

  mapParams.zoom.current = currentZoom // set the zoom parameters so it accessible accross the app
  map.setZoom(mapParams.zoom.current) // Set zoom of the map
}

function valueLimit(val, min, max) {
  return val < min ? min : (val > max ? max : val)
}

function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x)
}

export function mapSetPosition() {
  if (!mapResultsContainer) return

  mapUpdatePosition()

  window.addEventListener('resize', throttle(mapUpdatePosition, 16))
}

function mapUpdatePosition() {

  if (largeBP.matches) {
    if (mapContainer.parentNode !== mapResultsContent) {
      resultsList.parentNode.insertBefore(mapContainer, resultsList)
    }
  } else {
    if (mapContainer.parentNode !== mapResultsContainer) {
      mapResultsContainer.appendChild(mapContainer)
    }
  }
}

export function getReverseGeocodingData(lat, lng) {
  const latlng = new google.maps.LatLng(lat, lng);
  let adddress = ''
  // This is making the Geocode request
  geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
    // This is checking to see if the Geoeode Status is OK before proceeding
    if (status === 'OK') {
      adddress = results[0].formatted_address
      const adressInput = document.querySelector('[data-form-map-adress-input]')
      adressInput.value = adddress
      adressInput.classList.add('hasValue')
    }
  });
}

export { map }
