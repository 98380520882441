import { initSearchForm } from './MapForms'
import { initGeocoder, getReverseGeocodingData } from './MapMap'
import { updateMapInfoWindows } from './MapList'
import { createMap, mapSetPosition, adddress } from './MapMap'
import { MapFormSelectInit } from './MapFormSelect'
import { MapAddressAutoCompleteInit } from './MapAddressAutoComplete'
import { fetchStructures } from './MapFetchStructures'

// Default map Settings
const defaultZoomMax = 16
const defaultZoomMin = 6
const defaultZoomcurrent = 6

// Paris longitude and latitude coordiantes
const defaultLng = 2.3488
const defaultLat = 48.8534

// Default Pagination Settings
const defaulCurrentPage = 0
const limitPerPage = 10

// Default store params
const store = {
  structures: [],
  farthestStructure: null,
  listedStructureCount: 0,
  markers: [],
  filters: [],
  infoWindows: [],
  startLocation: {},
  service: 13,
  input: {
    address: '',
  }
}

let services = {
  "Centre de dépistage" : [13],
  "Test rapide en CeGIDD": [130, 138],
  'Consultation PrEP': [112],
  'Service hospitalier VIH' : [9],
  "Associations LGBT+" : [75,77]
}

// Default mapParams
const mapParams = {
  zoom: {
    max: defaultZoomMax,
    min: defaultZoomMin,
    current: defaultZoomcurrent
  },
  mapCenter: {
    lng: defaultLng,
    lat: defaultLat
  }
}

// Default pagination params
const listParams = {
  currentPage: defaulCurrentPage,
  limitPerPage: limitPerPage,
  pageNb: null
}

function init() {
  mapSetPosition()
  createMap()
  initGeocoder()
  initSearchForm()
  MapFormSelectInit()
  MapAddressAutoCompleteInit()
  initSearchFromOtherPage()
}

const results = document.querySelector('[data-map-resultsContent]')
const map = document.querySelector('[data-results-mapContainer]')
const intro = document.querySelector('[data-annuaire-intro]')

function initSearchFromOtherPage() {
  let url = document.URL
  url = new URL(url);
  const params = new URLSearchParams(url.search);
  const latParam = params.getAll('latitude').toString()
  const longParam = params.getAll('longitude').toString()
  const servParam = params.getAll('service').toString()

  if(latParam !== '' && longParam !== '' && servParam !== '') {
    let parameters = {
      latitude: latParam,
      longitude: longParam,
      service: servParam
    }

    mapParams.mapCenter = {
      lng: parseFloat(longParam),
      lat: parseFloat(latParam)
    }

    fetchStructures(parameters)

    if(results) {
      showResults()
      const anchor = document.querySelector('#MapAnchor')
      anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }

    const selectInput = document.querySelector('[data-form-center]')

    let servicesP = servParam.split(',')
    servicesP.forEach((key, service) => {
      servicesP[service] = parseInt(key)
    });

    Object.entries(services).forEach(([key, value]) => {
      const serviceName = key
      const serviceValue = value
      
      if(JSON.stringify(serviceValue) === JSON.stringify(servicesP)) {
        selectInput.setAttribute('data-center-id', servParam)
        selectInput.innerHTML = serviceName
      }
    });

    getReverseGeocodingData(parseFloat(latParam),parseFloat(longParam))

  }
}

export function showResults() {
  results.setAttribute('aria-hidden', 'false')
  map.setAttribute('aria-hidden', 'false')
  intro.setAttribute('aria-hidden', 'true')
}


export default { init }
export { listParams, mapParams, store }
