var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "SPFPlayer-playlist",
      class: { "is-playing": _vm.isPlaying }
    },
    _vm._l(_vm.medias, function(media, index) {
      return _c(
        "li",
        {
          key: media.id,
          staticClass: "SPFPlayer-playlistItem",
          class: { "is-active": _vm.currentMediaIndex == index },
          attrs: { "data-hover": "false" }
        },
        [
          _c("div", { staticClass: "SPFPlayer-playlistContainer" }, [
            _c(
              "header",
              {
                staticClass: "SPFPlayer-playlistButtonHeader",
                attrs: {
                  disabled: _vm.currentMediaIndex == index ? true : false
                },
                on: {
                  mouseenter: _vm.hoverHandler,
                  mouseleave: _vm.hoverHandler,
                  click: function($event) {
                    return _vm.updateMedia(index, $event)
                  }
                }
              },
              [
                _c("button", { staticClass: "SPFPlayer-playlistButton" }, [
                  _c("img", {
                    staticClass: "SPFPlayer-playlistButtonImage",
                    attrs: { src: media.image.src, alt: media.image.alt }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "SPFPlayer-playlistContentContainer" }, [
              _c(
                "button",
                {
                  staticClass: "SPFPlayer-playlistButton",
                  attrs: {
                    disabled: _vm.currentMediaIndex == index ? true : false
                  },
                  on: {
                    mouseenter: _vm.hoverHandler,
                    mouseleave: _vm.hoverHandler,
                    click: function($event) {
                      return _vm.updateMedia(index, $event)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "SPFPlayer-playlistButtonTitle" }, [
                    _vm._v(_vm._s(media.title))
                  ]),
                  _vm._v(" "),
                  _vm.descriptionOnPreview
                    ? _c("div", {
                        staticClass: "SPFPlayer-playlistButtonDescription",
                        domProps: { innerHTML: _vm._s(media.description) }
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("main", { staticClass: "SPFPlayer-playlistButtonContent" }, [
                _c(
                  "span",
                  { staticClass: "SPFPlayer-playlistButtonDuration" },
                  [_vm._v(_vm._s(media.duration))]
                ),
                _vm._v(" "),
                media.transcription_url
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "SPFPlayer-transcription SPFPlayer-playlistButtontranscription",
                        attrs: {
                          href: media.transcription_url,
                          target: "_blank",
                          rel: "nofollow"
                        },
                        on: { click: _vm.transcriptionHandler }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 512 512"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d:
                                  "M352.214 0H60.992v512h390.015V98.793L352.214 0zm47.575 90.002l-38.779-.005-.005-38.779 38.784 38.784zM90.992 482V30h240.011l.011 89.993 89.993.011V482H90.992z"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d:
                                  "M126 151.98h170v30H126zM126 211.98h259.01v30H126zM126 271.99h259.01v30H126zM126 331.99h259.01v30H126zM292.26 391.99H385v30h-92.74z"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Transcription")])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dateOnPreview
                  ? _c("div", { staticClass: "SPFPlayer-playlistButtonDate" }, [
                      _vm._v(_vm._s(media.date))
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }