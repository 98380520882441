<template>
  <ul class="SPFPlayer-playlist" :class="{ 'is-playing' : isPlaying }">
    <li class="SPFPlayer-playlistItem" data-hover="false" :class="{ 'is-active' : currentMediaIndex == index }" v-for="(media, index) in medias" :key="media.id">
      <div class="SPFPlayer-playlistContainer">

        <header 
          class="SPFPlayer-playlistButtonHeader"
          @mouseenter="hoverHandler"
          @mouseleave="hoverHandler"
          @click="updateMedia(index, $event)"
          :disabled="currentMediaIndex == index ? true : false"
        >
          <button
            class="SPFPlayer-playlistButton"
          >
            <img class="SPFPlayer-playlistButtonImage" :src="media.image.src" :alt="media.image.alt">
          </button>
        </header>

        <div class="SPFPlayer-playlistContentContainer">

          <button
            @mouseenter="hoverHandler"
            @mouseleave="hoverHandler"
            @click="updateMedia(index, $event)"
            :disabled="currentMediaIndex == index ? true : false"
            class="SPFPlayer-playlistButton"
          >
            <span class="SPFPlayer-playlistButtonTitle">{{ media.title }}</span>
            <div class="SPFPlayer-playlistButtonDescription" v-if="descriptionOnPreview" v-html="media.description"></div>
          </button>

          <main class="SPFPlayer-playlistButtonContent">

            <span class="SPFPlayer-playlistButtonDuration">{{ media.duration }}</span>

            <a @click="transcriptionHandler" class="SPFPlayer-transcription SPFPlayer-playlistButtontranscription" v-if="media.transcription_url" :href="media.transcription_url" target="_blank" rel="nofollow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M352.214 0H60.992v512h390.015V98.793L352.214 0zm47.575 90.002l-38.779-.005-.005-38.779 38.784 38.784zM90.992 482V30h240.011l.011 89.993 89.993.011V482H90.992z"/>
                <path fill="currentColor" d="M126 151.98h170v30H126zM126 211.98h259.01v30H126zM126 271.99h259.01v30H126zM126 331.99h259.01v30H126zM292.26 391.99H385v30h-92.74z"/>
              </svg>
              <span>Transcription</span>
            </a>

            <div class="SPFPlayer-playlistButtonDate" v-if="dateOnPreview">{{ media.date }}</div>
          </main>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Playlist',
  props: {
    eventBus: {
      required: true,
      type: Object
    },
    originMedias: {
      required: true,
      type: Array
    },
    descriptionOnPreview: {
      required: false,
      type: Boolean,
      default: false
    },
    dateOnPreview: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      medias: [],
      currentMediaIndex: '',
      currentEl: false,
      isPlaying: false
    }
  },
  methods: {
    // Notify the player to change the current media on click on playlist element
    updateMedia(mediaIndex, event) {

      if(event){
        this.currentEl = event.target.closest('.SPFPlayer-playlistItem')
      }

      if (this.medias[mediaIndex] !== undefined) {
        if (mediaIndex == this.currentMediaIndex) {          

          if(this.currentEl.classList.contains('is-active')) {
            // this.currentEl.classList.remove('is-active')
          }else {
            this.currentEl.classList.add('is-active')
          }

          this.eventBus.$emit('Playlist::ToggleMediaState', this.isPlaying)

        }else {
          this.eventBus.$emit('Playlist::UpdateMedia', mediaIndex)
          this.isPlaying = true
        }
      }
      
      const allPodcast = document.querySelectorAll('[data-hover]')

      allPodcast.forEach(podcast => {
        podcast.setAttribute('data-hover', 'false')
      });
    },
    transcriptionHandler() {

      const medias = document.querySelectorAll('audio, video')

      setTimeout(() => {
        
        medias.forEach(media => {
          media.pause()
        });
      }, 100);
    },
    hoverHandler (e){
      const currentElement = e.target
      const parentElement = currentElement.closest('[data-hover]')
      const attr = (parentElement.getAttribute('data-hover') === 'true')

      if(attr) {
        this.removeHover(parentElement)
      }else {
        this.addHover(parentElement)
      }
    }, 
    addHover(parentElement) {
      parentElement.setAttribute('data-hover', 'true')
    },
    removeHover(parentElement) {
      parentElement.setAttribute('data-hover', 'false')
    }
  },
  mounted() {
    this.medias = this.originMedias
    this.currentMediaIndex = 0

    this.eventBus.$on('Player::UpdateMedia', ({ index } = payload) => {
      this.currentMediaIndex = index
      this.isPlaying = true
    })

    this.eventBus.$on('Player::UpdatePlayingState', isPlaying => {
      this.isPlaying = isPlaying
    })

    this.eventBus.$on('Player::playlistPauseUpdate', () => {
      const el = document.querySelector('.is-active') 
      if(!el) return
      el.classList.add('is-paused')
    })
    
    this.eventBus.$on('Player::playlistPauseUpdate', () => {
      const el = document.querySelector('.is-active') 
      if(!el) return
      if(el.classList.contains('is-paused'))
        el.classList.remove('is-paused')
    })
  }
}
</script>
