<template>
  <div class="Optin">
    <div class="Optin-container">
      <div class="Form-row">

        <button @click="legalHandler" type="button" class="Chat-legalButton" data-legal-button="" aria-expanded="false">Afficher les conditions</button>
        <div class="Chat-legal" data-legal="" :aria-hidden="isLegalOpen">
        La  commande d’auto-test ainsi que les entretiens préalables sont des services proposés par l’association AIDES.
        <br><br>
        En validant le formulaire, j’accepte la transmission de mes coordonnées à cette association qui les traitera, dans le respect de la loi informatique et libertés, uniquement pour la gestion des commandes et des auto-tests, ainsi que pour les entretiens préalables. L’Association Aides est responsable du traitement de vos données.
        <br><br>
        Pour en savoir plus sur ce traitement et sur les modalités d’exercice de vos droits sur vos données auprès de l’association AIDES, vous pouvez consulter la page de confidentialité du site SEXOSAFE ou consulter les mentions légales du site <a href="https://jefaisletest.fr/" target="_blank">jefaisletest.fr</a>.</div>

        <label :for="`Optin-${answer.id}`" class="Optin-inputContainer">
          <input 
            class="Optin-input Form-input"
            type="checkbox"
            :name="`Optin-${answer.id}`"
            :id="`Optin-${answer.id}`"
            @change="isChecked"
            :required="answer.required"
          />
          <span class="Optin-label" :for="`Optin-${answer.id}`">{{ answer.label }}</span>
          <span class="checkmark-container">
            <span class="checkmark"></span>
          </span>
        </label>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name:"Optin",
  data() {
    return {
      toEmit: {},
      nextActionData: {},
      isLegalOpen: 'true'
    }
  },
  props: {
    answer: {
      required: true,
      type: Object
    }
  },
  methods: {
    isChecked(event) {
      
      if(event.target.checked){
        this.toEmit = { "questionId": this.answer.id, "questionLabel": this.answer.label, "questionValue": event.target.checked, "questionIdentifier": this.answer.identifier }
        this.$emit('Optin::isChecked', true)
      }else {
        this.toEmit = { "questionId": this.answer.id, "questionLabel": this.answer.label, "questionValue": "", "questionIdentifier": this.answer.identifier }
        this.$emit('Optin::isChecked', false)
      }

      this.sendData() 
      this.sendAction()

    },
    sendData(){
      this.$emit('Optin::set-data', this.toEmit)
    },
    sendAction(){

      this.nextActionData.componentType = "Result"
      this.nextActionData.id = this.answer.goResult
      this.nextActionData.lastActionId = this.answer.id
      this.$emit('Optin::set-Action')
    },
    legalHandler(e) {

      const el = e.target
      if(this.isLegalOpen === "true") {
        el.innerHTML = "Masquer les conditions"
        this.isLegalOpen = "false"
      }else {
        this.isLegalOpen = "true"
        el.innerHTML = "Afficher les conditions"
      }
    }
  },
}
</script>