
class Modal {
  constructor(modal) {
    this.modal = modal
    this.triggerElements = null
    this.activeTrigger = null
    this.mainContent = document.querySelector('.main-content')
    this.body = document.querySelector('body')
  }
  

  init() {

    if(!this.modal) return

    const hasTriggers = this.checkTrigger()

    if(!hasTriggers)return

    this.initModal()

    this.KeyboardCloseModal()
    this.keyboardLoopTabulation(this.modal)
    
  }

  checkTrigger() {
    const modalId = this.modal.getAttribute('data-modal')
    const triggerElements = document.querySelectorAll(`[data-modal-trigger=${modalId}]`)
    this.triggerElements = triggerElements

    return triggerElements.length
  }

  initModal() {
    this.addTriggerListener()
  }

  addTriggerListener() {
    this.triggerElements.forEach(element => {
      element.addEventListener('click', (e) => {
        this.apparitionHandler(element.parentElement.querySelector('.PlaylistButton-title'))
      })
    });
  }

  apparitionHandler(element) {
    const isHidden = (this.modal.getAttribute('aria-hidden') === 'true')
    let title
    if(element)
      title = element.innerHTML

    if(isHidden) {
      this.showModal()
      if(element)
        this.modal.setAttribute('aria-labelledby', title)
    }else {
      this.hideModal()
      if(element)
        this.modal.setAttribute('aria-labelledby', '')
    }
  }

  showModal() {

    const videoBtn = this.modal.querySelector('.SPFPlayer-playerButton')
    this.modal.style.display = "block"
    const loopStartElement = this.modal.querySelector('[data-modal-loop-start]')
        
    
    setTimeout(() => {
      this.modal.setAttribute('aria-hidden', 'false')
      this.mainContent.setAttribute('aria-hidden', 'true')
      this.body.style.overflow = "hidden"
      videoBtn.click()
      loopStartElement.focus()
    }, 100);
  }
  
  KeyboardCloseModal() {    
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {

        const isOpen = (this.modal.getAttribute('aria-hidden') === 'true')
        
        if(!isOpen) {
          this.hideModal()
        }
      }
    });
  }

  keyboardLoopTabulation(modal) {

    const loopStartElement = modal.querySelector('[data-modal-loop-start]')
    const loopEndElement = modal.querySelector('[data-modal-loop-end]')

    const dummyStart = modal.querySelector('[data-modal-dummytoFocus-start]')
    const dummyEnd = modal.querySelector('[data-modal-dummytoFocus-end]')

    loopStartElement.addEventListener('focus', function(e){
      dummyEnd.focus()
    }, true )
      
    loopEndElement.addEventListener('focus', function(e){
      dummyStart.focus()
    }, true )
  }

  hideModal() {
    const videoBtn = this.modal.querySelector('.SPFPlayer-playerButton')
    const video = this.modal.querySelector('video')

    videoBtn.click()
    video.pause()

    this.modal.setAttribute('aria-hidden', 'true')
    this.mainContent.setAttribute('aria-hidden', 'false')
    this.body.removeAttribute('style')

    setTimeout(() => {
      this.modal.style.display = "none"
    }, 300);

  }

}

export default Modal 







