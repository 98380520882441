import { listParams, showResults, store, mapParams } from './Map'
import { fetchStructures } from './MapFetchStructures'
import { geocodeAddress, map } from './MapMap'

const mapForm = document.querySelector('[data-map-form-search], [data-map-form-home]')
const mapFormSubmitButtons = document.querySelectorAll('[data-form-adress-button], [data-map-select-option]')
const anchor = document.querySelector('#MapAnchor')


// Init map form
export function initSearchForm() {
  
  if (!mapForm) return
  
  mapFormSubmitButtons.forEach(button => {
    button.addEventListener('click', formSubmitHandler)
  });
  
}

// Set store adress and Update the map on form submit
export const formSubmitHandler = (e) => {
  e.preventDefault() // prevent form submitting
  
  const adressInputValue = mapForm.querySelector('[data-form-map-adress-input]').value
  
  store.input = {
    address: adressInputValue,
  }
  
  listParams.currentPage = 0
  
  if(mapForm.hasAttribute('data-map-form-search')) {
    geocodeAddress(adressInputValue, fetchStructures) // update the map, the store and mapParams
    showResults()
    if(!e.target.hasAttribute('data-map-select-option'))
      anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }else {

    if(e.target.closest('.homepage-mapForm-container')){
      if(e.target.hasAttribute('data-form-adress-button')) {
        geocodeAddress(adressInputValue, redirectUrl) // update the map, the store and mapParams
      }
    }
  }

}

function redirectUrl() {
  const annuaireUrl = mapForm.getAttribute('data-annuaire-url')
  const url = `${annuaireUrl}${window.location.search}?latitude=${mapParams.mapCenter.lat}&longitude=${mapParams.mapCenter.lng}&service=${store.service}`;
  location.href = url;
}

