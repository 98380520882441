var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "Introduction container container--small container--isStatic "
    },
    [
      _c("div", { staticClass: "container-container" }, [
        _c("div", { staticClass: "container-inner" }, [
          _c(
            "div",
            { staticClass: "container-content" },
            [
              _c("Sticker", [
                _c("span", [_vm._v(_vm._s(_vm.introduction.sticker.textThin))]),
                _vm._v(" "),
                _c("span", { staticClass: "bold" }, [
                  _vm._v(_vm._s(_vm.introduction.sticker.textBold))
                ])
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "Introduction-title TPEForm-title " }, [
                _vm._v(_vm._s(_vm.introduction.title))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "Introduction-text" }, [
                _vm._v(_vm._s(_vm.introduction.text))
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "Button Button--white",
                  on: { click: _vm.startQuestion }
                },
                [
                  _c("span", { staticClass: "Button-label" }, [
                    _vm._v(_vm._s(_vm.introduction.buttonLabel))
                  ])
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }