var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Question container container--small container--isStatic " },
    [
      _c("div", { staticClass: "container-container" }, [
        _c("div", { staticClass: "container-inner" }, [
          _c(
            "div",
            { staticClass: "container-content" },
            [
              _c(
                "transition-group",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c(
                    "div",
                    {
                      key: _vm.questions[_vm.index].title,
                      staticClass: "Question-container"
                    },
                    [
                      _c("div", { staticClass: "Question-header" }, [
                        _c(
                          "h3",
                          { staticClass: "Question-title TPEForm-title" },
                          [_vm._v(_vm._s(_vm.questions[_vm.index].title))]
                        ),
                        _vm._v(" "),
                        _vm.questions[_vm.index].text
                          ? _c("p", { staticClass: "Question-text" }, [
                              _vm._v(_vm._s(_vm.questions[_vm.index].text))
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "Question-answers" },
                        _vm._l(_vm.questions[_vm.index].answers, function(
                          answer,
                          index
                        ) {
                          return _c(
                            "button",
                            {
                              key: _vm.questions[index].title,
                              staticClass:
                                "Question-answer Button Button--white",
                              on: {
                                click: function($event) {
                                  return _vm.nextAction(answer)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "Button-label" }, [
                                _vm._v(_vm._s(answer.label))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }