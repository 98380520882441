var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Optin" }, [
    _c("div", { staticClass: "Optin-container" }, [
      _c("div", { staticClass: "Form-row" }, [
        _c(
          "button",
          {
            staticClass: "Chat-legalButton",
            attrs: {
              type: "button",
              "data-legal-button": "",
              "aria-expanded": "false"
            },
            on: { click: _vm.legalHandler }
          },
          [_vm._v("Afficher les conditions")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "Chat-legal",
            attrs: { "data-legal": "", "aria-hidden": _vm.isLegalOpen }
          },
          [
            _vm._v(
              "\n      La  commande d’auto-test ainsi que les entretiens préalables sont des services proposés par l’association AIDES.\n      "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "\n      En validant le formulaire, j’accepte la transmission de mes coordonnées à cette association qui les traitera, dans le respect de la loi informatique et libertés, uniquement pour la gestion des commandes et des auto-tests, ainsi que pour les entretiens préalables. L’Association Aides est responsable du traitement de vos données.\n      "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "\n      Pour en savoir plus sur ce traitement et sur les modalités d’exercice de vos droits sur vos données auprès de l’association AIDES, vous pouvez consulter la page de confidentialité du site SEXOSAFE ou consulter les mentions légales du site "
            ),
            _c(
              "a",
              { attrs: { href: "https://jefaisletest.fr/", target: "_blank" } },
              [_vm._v("jefaisletest.fr")]
            ),
            _vm._v(".")
          ]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "Optin-inputContainer",
            attrs: { for: "Optin-" + _vm.answer.id }
          },
          [
            _c("input", {
              staticClass: "Optin-input Form-input",
              attrs: {
                type: "checkbox",
                name: "Optin-" + _vm.answer.id,
                id: "Optin-" + _vm.answer.id,
                required: _vm.answer.required
              },
              on: { change: _vm.isChecked }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "Optin-label",
                attrs: { for: "Optin-" + _vm.answer.id }
              },
              [_vm._v(_vm._s(_vm.answer.label))]
            ),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkmark-container" }, [
      _c("span", { staticClass: "checkmark" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }