<template>
  <div class="InputRadio ">

    <button
      class="InputRadio-answer Button Button--white"
      @click="nextAction(answer)"
      >
      <span class="Button-label">{{ answer.label }}</span>
    </button>

  </div>
</template>

<script>
export default {
  name: "InputRadio",
  props: {
    questions: {
      required: true,
      type: Array,
    },
    answer: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data(){
    return {
      nextActionData: {},
      userData: {},
    }
  },
  methods: {
    nextAction(answer) {

      const answerCopy = {...answer}
      this.setAction(answer)
      this.setData(answerCopy)

    },
    setAction(answer) {

      if(answer.goAnswerId) {
        this.nextActionData.componentType = "Result"
        this.nextActionData.id = answer.goAnswerId
        this.nextActionData.lastActionId = answer.id
      }
      if(answer.goDynamicQuestion) {
        this.nextActionData.componentType = "DynamicQuestion"
        this.nextActionData.id = answer.goDynamicQuestion
        this.nextActionData.lastActionId = answer.id
      }

      this.$emit("InputRadio::set-action", this.nextActionData)

    },
    setData(answer) {

      this.userData.questionId = answer.id
      this.userData.questionLabel = answer.label
      this.userData.questionValue = answer.value
      this.userData.questionIdentifier = answer.identifier
      this.userData.nextAction = true

      this.$emit("InputRadio::set-data", this.userData)
      this.userData = []
    },
  }
}
</script>