import Modal from "../embed/modal/modal"

const DATA_MODAL_SELECTOR = '[data-modal]'

const modals = document.querySelectorAll(DATA_MODAL_SELECTOR)

function init() {

  if(!modals.length) return
  createInstances()

}

function createInstances() {
  modals.forEach(modal => {
    const modalInstance = new Modal(modal)
    modalInstance.init()
  });
}

export default { init }
