
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let keyword = urlParams.get('search')

const ElementsToParse = document.querySelectorAll('.SearchResult .SearchResult-title, .SearchResult .SearchResult-text')

function init() {
  
  if(!ElementsToParse.length) return
  if(!keyword) return
  keyword = keyword.toLowerCase()
  initResultsParsing()
} 

function initResultsParsing() {
  ElementsToParse.forEach(element => {
    const text = element.innerHTML
    const isPresent = text.includes(keyword)

    if(isPresent) {
      if(text) {
        const splittedText = text.split(' ')
        if(splittedText.length) {
          splittedText.forEach((item, index, splittedText) => {
            if(item === keyword) {
              splittedText[index] = `<span class="underlineText">${item}</span>`
            }
          }); 
          let newString = ''
          splittedText.forEach(item => {
            newString += `${item} `
          });
          element.innerHTML = newString
        }
      }
    }
  });
}

 
export default { init }