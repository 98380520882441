<template>
  <div class="TestResult" data-test-result>
    <div class="container container--small container--isStatic">
      <div class="container-container">
        <div class="container-inner">
          <div class="container-content">
            <div class="Result-loader"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loader in white part -->
    <div class="container container--small container--isStatic container--white">
      <div class="container-container">
        <div class="container-inner">
          <div class="container-content">
            <div class="Result-loader"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import listArticle from '../../../ListArticle/ListArticle'

export default {
  name: "TestResult",
  props: {
    result: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      siblings: []
    }
  },
  methods: {
    getSiblings(e) {
      // for collecting siblings
      let siblings = [];

      // if no parent, return no sibling
      if(!e.parentNode) {
        return siblings;
      }
      
      // first child of the parent node
      let sibling  = e.parentNode.firstChild;
      
      // collecting siblings
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== e) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      return siblings;
    },
    mouseOver(e) {

      this.siblings = this.getSiblings(e.target)
      
      this.siblings.forEach(sibling => {
        sibling.style.opacity = ".5"
      });
    },
    mouseLeave(e) {  

      this.siblings = this.getSiblings(e.target)

      this.siblings.forEach(sibling => {
        sibling.style.opacity = "1"
      });

      e.target.style.opacity = "1"

      setTimeout(() => {
        this.siblings = []
      }, 100);

    },
  },
  async mounted() {

    let dataTestResult = document.querySelector('[data-test-result]')
    let data = window.localStorage.getItem('test_data');

    await axios.post(
        '/send_aides_api',data
    ).then(async (response) => {
      dataTestResult.innerHTML = await response.data
      return tC.event.jeFaisLeTestFormSent(this, {'formName':'Je fais le test'});
    }).catch((error) => {
      console.log(error);
    })

    listArticle.init()
  }
}
</script>