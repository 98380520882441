<template>
  <div class="Progression" :class="progressionIndex === 0 ? 'Progression--hidden' : ''">
    <transition name="grow" mode="out-in" appear>
      <div class="Progression-tracker" :style="{ width: this.trackerWidth }"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Progression",
  props: {
    progressionIndex: {
      required: true,
      type: Number,
    },
    progressionlength: {
      required: true,
      type: Number,
    }
  },
  computed: {
    trackerWidth() {
      const progress = (this.progressionIndex / this.progressionlength) * 100
      return `${progress}%` 
    }
  },
}
</script>