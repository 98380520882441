var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "InputCheckbox" }, [
    _c("div", { staticClass: "InputCheckbox-container" }, [
      _c(
        "div",
        { staticClass: "Form-row" },
        _vm._l(_vm.answer.list, function(input, index) {
          return _c("div", { key: index }, [
            _c("input", {
              staticClass: "InputCheckbox-input Form-input",
              attrs: {
                type: "checkbox",
                name: "InputCheckbox-" + input.id,
                id: "InputCheckbox-" + input.id,
                "data-label": input.label,
                "data-value": input.value,
                "data-identifier": input.identifier
              },
              on: { change: _vm.setData }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "InputCheckbox-label Form-label",
                attrs: { for: "InputCheckbox-" + input.id }
              },
              [_vm._v(_vm._s(input.label))]
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }