var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InputMail",
      class: _vm.hasChanged
        ? _vm.isCorrect
          ? "InputMail--valid"
          : "InputMail--error"
        : ""
    },
    [
      _c("div", { staticClass: "InputMail-container" }, [
        _c(
          "div",
          { staticClass: "Form-row" },
          [
            _c("input", {
              staticClass: "InputMail-input Form-input",
              class: {
                "InputMail-input--valid": _vm.hasChanged && _vm.isCorrect,
                "InputMail-input--error": _vm.hasChanged && !_vm.isCorrect,
                hasValue: this.inputValue.length
              },
              attrs: {
                type: "text",
                name: "InputMail-" + _vm.answer.id,
                id: "InputMail-" + _vm.answer.id,
                required: _vm.answer.required
              },
              on: { keyup: _vm.isCorrectValue }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "Form-label",
                attrs: { for: "InputMail-" + _vm.answer.id }
              },
              [_vm._v(_vm._s(this.answerLabel))]
            ),
            _vm._v(" "),
            _c("FormInputIcon", { attrs: { isLoading: false } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "InputMail-message" }, [
          _vm._v(
            "L'adresse email ne peut pas être vide et doit être au format exemple@domaine.fr"
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }