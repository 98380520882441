var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "InputRadio " }, [
    _c(
      "button",
      {
        staticClass: "InputRadio-answer Button Button--white",
        on: {
          click: function($event) {
            return _vm.nextAction(_vm.answer)
          }
        }
      },
      [
        _c("span", { staticClass: "Button-label" }, [
          _vm._v(_vm._s(_vm.answer.label))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }