import Vue from 'vue'
import TPEForm from './TPEForm.vue'

Vue.config.devtools = true

let self = {}

self.init = function() {
  // Init player for each player on the current page

  const tpeForms = document.querySelectorAll('[data-form="TPE"]')
  tpeForms.forEach((TPEFormContainer) => {

    // Create eventBus to centralize event transmission between component
    const eventBus = new Vue()

    const data = JSON.parse(TPEFormContainer.getAttribute('data'))

    let propsForm = {
      eventBus: eventBus,
      questions: data.questions,
      results: data.results,
      introduction: data.introduction
    }

    new Vue({
      render: h => h(TPEForm, { props: propsForm })
    }).$mount(TPEFormContainer)
  })
}

export default self
