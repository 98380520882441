<template>
  <div class="DynamicQuestion container container--small container--isStatic" >
    <!-- :class="{'DynamicQuestion-progression': hasProgression}" -->
    <div class="container-container">
      <div class="container-inner">
        <div class="container-content">

          <transition-group name="fade" mode="out-in" >
            <div class="DynamicQuestion-container" :key="questions[index].title" :class="autocompleteQuestion ? 'DynamicQuestion-container--extraPadding' : ''">

              <div class="DynamicQuestion-header">
                <h3 class="DynamicQuestion-title TPEForm-title">{{ questions[index].title }}</h3>
                <p v-if="questions[index].text" :class="{'DynamicQuestion-text--bold': questions[index].id == 0}" class="DynamicQuestion-text">{{ questions[index].text }}</p>
                <p v-if="questions[index].additionalInfos" class="DynamicQuestion-text DynamicQuestion-text--bold">{{ questions[index].additionalInfos }}</p>
                <p v-if="questions[index].hasRequired" class="DynamicQuestion-text DynamicQuestion-text DynamicQuestion-text--small">* champs obligatoires</p>
              </div>

              <div class="DynamicQuestion-answers" :class="{ 'DynamicQuestion-answers--row' :questions[index].style === 'row'}">

                <div v-if="questions[index].id == 2">
                  <Optin 
                    :answer="questions[index].answers[0]"
                    @Optin::isChecked="setDisabledButton"
                  />
                </div>

                <component v-else
                  v-for="(answer, index) in questions[index].answers"
                  :key="`input-${index}`"
                  :is="answer.type"
                  :type="answer.type"
                  :answer="answer"
                  :questions="questions"
                  :question="questions[index]"
                  :index="index"
                  @Optin::set-data="setData"
                  @Optin::set-action="setAction"
                  @InputAddress::set-type="setType"
                  @InputAddress::set-data="setData"
                  @InputText::set-data="setData"
                  @InputMail::set-data="setData"
                  @InputTel::set-data="setData"
                  @InputRadio::set-action="setAction"
                  @InputRadio::set-data="setData"
                  @InputSelect::set-data="setData"
                  @InputCheckbox::set-data="setMultipleData"
                  @InputSelect::set-action="setAction"
                >
                </component>


                <div class="DynamicQuestion-actions" v-if="questions[index].nextButton" :class="{ 'DynamicQuestion-actions--row' : questions[index].style === 'row', 'DynamicQuestion-actions-transitionQuestion' : questions[index].id == 2 }">
                  <button
                    class="DynamicQuestion-answer Button Button--black"
                    :disabled="isDisabled || isSkipable"
                    @click="nextAction"
                  >
                    <span class="Button-label">{{ questions[index].nextButton.label }}</span>
                  </button>
                  <button
                    class="DynamicQuestion-answer Button Button--black"
                    :class="{ 'DynamicQuestion-answer--inline' : questions[index].id == 2 }"
                    @click="skipAction"
                    v-if="questions[index].skipButton"
                  >
                    <span class="Button-label">{{ questions[index].skipButton.label }}</span>
                  </button>
                </div>
              </div>

            </div>
          </transition-group>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputText from '../InputText/InputText.vue'
import InputAddress from '../InputAddress/InputAddress.vue'
import InputMail from '../InputMail/InputMail.vue'
import InputTel from '../InputTel/InputTel.vue'
import InputRadio from '../InputRadio/InputRadio.vue'
import InputSelect from '../InputSelect/InputSelect.vue'
import InputCheckbox from '../InputCheckbox/InputCheckbox.vue'
import Optin from '../Optin/Optin.vue'

export default {
  name: "DynamicQuestion",
  components: {
    InputText,
    InputAddress,
    InputMail,
    InputTel,
    InputRadio,
    InputSelect,
    InputCheckbox,
    Optin,
  },
  props: {
    questions: {
      required: true,
      type: Array,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data(){
    return {
      nextActionData: {},
      staggingData: [],
      staggingArrayKeys: [],
      userData: [],
      arrayKeys: [],
      isnotEmpty: false,
      isSkipable: this.questions[this.index].id == 2 ? true : false,
      isFirst: true,
      autocompleteQuestion: false 
    }
  },
  computed: {
    answersNumber() {
      return this.questions[this.index].answers.length 
    },
    isDisabled() {
      let disabled = true 

      if (this.isnotEmpty){
        disabled = false
      }else if(!this.isnotEmpty && !this.questions[this.index].answers){
        disabled = false
      }else if(this.isnotEmpty){
        disabled = true
      }
      return disabled
    },
  },
  methods: {
    setDisabledButton(e) {
      if(e) {
        this.isnotEmpty = true
      }else{
        this.isnotEmpty = false
      }
    },
    setType() {
      this.autocompleteQuestion = true
    },
    setData(answer) {
      const answerCopy = {...answer}
      const answerId = answerCopy.questionId
      const alreadyExist = this.arrayKeys.includes(answerId)

      if(!alreadyExist){ // add answer to the userDataArray
        this.userData.push(answerCopy)
      }else{ // Update existing data
        const index = this.arrayKeys.indexOf(answerId)
        this.userData[index].questionValue = answerCopy.questionValue
      }
      
      this.updateKeys()
      this.verifyData()

      if(answer.nextAction){
        this.$emit('DynamicQuestion::set-data', this.userData)
        this.resetStorage()
      }

    },
    setMultipleData(answer) {

      this.isnotEmpty = !answer.length ? false : true
      const newObj = {"answers": answer, "questionId":  this.questions[this.index].id}
      this.userData.push(newObj)

      if(!this.isFirst){
        this.userData.splice(-1)
      }

      this.isFirst = false
    },
    updateKeys() {
      this.userData.forEach(data => {
        let answerKey = data.questionId
        let alreadyExist = this.arrayKeys.includes(answerKey)

        if(!alreadyExist){
          this.arrayKeys.push(answerKey)
        }
      });
    },
    verifyData() {

      let i = 0

      this.userData.forEach(data => {
        let value = data.questionValue
        let isEmpty = (value === "")

        if(!isEmpty) i++
      });

      this.isnotEmpty = i !== this.answersNumber ? false : true
      i = 0

    },
    nextAction() {
      
      if(this.isnotEmpty){
        this.$emit('DynamicQuestion::set-data', this.userData)
      }else{
        if(this.questions[this.index].type){ // here to send an empty array so the previousAction that delete the last entry of the localStorage work 
          this.$emit('DynamicQuestion::set-data', {})
        }
      }

      this.resetStorage()
      this.setAction()
    },
    resetStorage() {
      // reset storage for further question
      this.isnotEmpty = false
      this.userData = []
      this.arrayKeys = []
      this.isFirst = true
      this.autocompleteQuestion = false

    },
    skipAction() {
      const isSkipButton = this.questions[this.index].skipButton 

      if(isSkipButton) {
        if(isSkipButton.goResult) {
          this.nextActionData.componentType = "TestResult"
          this.nextActionData.lastActionId = this.questions[this.index].id
          this.nextActionData.id = this.questions[this.index].skipButton.goResult
        }
        if(isSkipButton.goDynamicQuestion) {
          this.nextActionData.componentType = "DynamicQuestion"
          this.nextActionData.lastActionId = this.questions[this.index].id
          this.nextActionData.id = this.questions[this.index].skipButton.goDynamicQuestion
        }
      }

      this.$emit("DynamicQuestion::set-action", this.nextActionData)

    },
    setAction() {

      const isNexButton = this.questions[this.index].nextButton 
      const isnextAction = this.questions[this.index].nextAction 

      if(isNexButton) {
        if(isNexButton.goResult) {
          this.nextActionData.componentType = "TestResult"
          this.nextActionData.lastActionId = this.questions[this.index].id
          this.nextActionData.id = this.questions[this.index].nextButton.goResult
        }else {
          this.nextActionData.componentType = "DynamicQuestion"
          this.nextActionData.lastActionId = this.questions[this.index].id
          this.nextActionData.id = this.questions[this.index].nextButton.goDynamicQuestion
        }
      }

      if(isnextAction) {
        this.nextActionData.componentType = "DynamicQuestion"
        this.nextActionData.id = this.questions[this.index].nextAction.goDynamicQuestion
        this.nextActionData.lastActionId = this.questions[this.index].id
      }

      this.$emit("DynamicQuestion::set-action", this.nextActionData)
    },
  },
}
</script>