import throttle from '../../js/helpers/throttle'

function init() {
  stickHeader()
  menuDisplay()
}

// Reduce header to hide top logos (only for home and landing pages)
function stickHeader() {
  const homeHeader = document.querySelector('[data-header-extended]')
  const body = document.querySelector('body')

  let scrollPos = window.pageYOffset
  let offset

  if (window.matchMedia("(min-width: 1100px)").matches) { 
    offset = 124
  } else {
    offset = 75
  }

  if (scrollPos > offset) {
    homeHeader.classList.add('Header--sticky')
  }

  window.addEventListener('resize', throttle(() => {
    if (window.matchMedia("(min-width: 1100px)").matches) {
      offset = 75
    } else {
      offset = 100
    }
  }, 50))

  window.addEventListener('scroll', function() {
    scrollPos = window.pageYOffset
    if (scrollPos > offset) {
      homeHeader.classList.add('Header--sticky')
      body.setAttribute('data-header-sticky', 'true')
    } else {
      homeHeader.classList.remove('Header--sticky')
      body.setAttribute('data-header-sticky', 'false')
    }
  })

}

function menuDisplay() {
  const menuDesktopTrigger = document.querySelector('[data-menuDesktop-trigger]')

  if (!menuDesktopTrigger) return

  const menuDesktop = document.querySelector('[data-menuDesktop]')
  let scrollPos = 0
  let lastScrollPos = 0

  window.addEventListener('scroll', throttle(function() {
    scrollPos = window.pageYOffset

    if (scrollPos > 150 && scrollPos > lastScrollPos) {
      document.body.classList.add('menu-hidden')
      menuDesktopTrigger.setAttribute('aria-expanded', false)
    } else {
      document.body.classList.remove('menu-hidden')
      menuDesktopTrigger.setAttribute('aria-expanded', true)
    }

    lastScrollPos = scrollPos <= 0 ? 0 : scrollPos // For Mobile or negative scrolling
  }, 100))

  menuDesktopTrigger.addEventListener('click', () => {
    document.body.classList.remove('menu-hidden')

    menuDesktopTrigger.setAttribute('aria-expanded', true)
    menuDesktop.querySelector('.MenuDesktop-item:first-child .MenuDesktop-link').focus()
  })
}

export default { init }