var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "TestResult", attrs: { "data-test-result": "" } },
      [
        _c(
          "div",
          { staticClass: "container container--small container--isStatic" },
          [
            _c("div", { staticClass: "container-container" }, [
              _c("div", { staticClass: "container-inner" }, [
                _c("div", { staticClass: "container-content" }, [
                  _c("div", { staticClass: "Result-loader" })
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "container container--small container--isStatic container--white"
          },
          [
            _c("div", { staticClass: "container-container" }, [
              _c("div", { staticClass: "container-inner" }, [
                _c("div", { staticClass: "container-content" }, [
                  _c("div", { staticClass: "Result-loader" })
                ])
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }