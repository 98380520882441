var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "PreviousButton PreviousButton--popin",
      attrs: { type: "button", "data-form-button": "previous" },
      on: { click: _vm.previousAction }
    },
    [
      _c(
        "svg",
        {
          staticClass: "icon",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "10",
            height: "10"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M6.804688 0 1.792969 5.011719l5.011719 5.007812 1.421874-1.421875-3.589843-3.585937 3.589843-3.589844Zm0 0"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("span", [_vm._v("Précédent")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }